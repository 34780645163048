import React, { useEffect, useState } from 'react';
import { Tabs, TabsProps } from 'antd';
import { copyPlaintext, onAdd} from '../util/api';
import { useLocation } from 'react-router-dom';
import avaImg from '../assets/img/ava.png'
import copyImg from '../assets/img/copy.png'
import '../assets/css/profile.less'
import toast from 'react-hot-toast';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useWeb3Modal } from '@web3modal/ethers/react'
import ProfileTable from './token/ProfileTable';
import Collect from './profile/Collect';
import ErcToken from './profile/ErcToken';
import Oders from './profile/Oders';
import { chaninArr } from '../util/config';

const Profile: React.FC = () => {

  const { address , chainId } = useWeb3ModalAccount();
  const { open } = useWeb3Modal()

  const [value,setvalue] = useState('1')
  let localtion = useLocation();

  useEffect(()=>{
    if(localtion.state){
        console.log(localtion.state)
        setvalue('2')
    }
    if(localStorage.getItem('profile')){
      let key:any = localStorage.getItem('profile')
      setvalue(key)
    }
  },[])

  useEffect(() => {
    if (address) {
      if (chainId != chaninArr[2].chainId) {
        toast.error('Please refresh the switch chain')
        open()
      }
    }
  }, [address])

  const items: TabsProps['items'] = [
    {
      key: '1',
      label: 'Collected',
      children:  <Collect></Collect>,
    },
    {
      key: '2',
      label: 'Publish Contract',
      children: <ProfileTable></ProfileTable>,
    },
    {
      key: '3',
      label: 'Orders',
      children: <Oders></Oders>,
    },
    {
      key: '4',
      label: 'Tokens',
      children: <ErcToken></ErcToken>,
    },
  ];

  const onChange = (key: string) => {
    console.log(key);
    localStorage.setItem('profile',key)
    setvalue(key)
  };


  const onCopy = async(url:string)=>{
    let res = await copyPlaintext(url);
    if(res){
      toast.success('copy success!')
    }else{
      toast.error('copy failed!')
    }
  }
  
  return (
    <div className='content Profile_box'>
      <div className='Profile_top'>
        <img src={avaImg} alt="" />
        <p>{ onAdd(address || '') || 'Please connect wallet'}</p>
        {address && <img src={copyImg} className="copy" onClick={()=>onCopy(address)} alt="" />}
      </div>

      <div className='Profile_tab'>
        <Tabs activeKey={value} popupClassName="bbb" items={items} onChange={onChange} />
      </div>
    </div>

  );
};


export default Profile;


