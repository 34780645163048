import React, { useContext, useEffect, useState } from 'react';
import usdt from '../assets/img/usdt.png';
import eth from '../assets/img/eth.png';
import '../assets/css/swap.less'
import Echarts from '../component/Echarts';
import { Button } from 'antd';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { switchNetwork } from '../util/api';

const SwapView = () => {

  const { walletProvider }:any = useWeb3ModalProvider()
  const { chainId, isConnected } = useWeb3ModalAccount()

  const onBSC = ()=>{
    // if(isConnected && BSCText.chainId != chainId){
    //   alert(chainId)
    //   switchNetwork(walletProvider,BSCText)
    // }
  }

  return <div className='swapBox '>
    <div className='content'>
    <div className='swap_left'>
      <div>
        <img src={eth} alt="" />
        <img src={usdt} alt="" />
        <p>ETH / USDT</p>
      </div>
      <div>2,416.1498 <i>+2.18%</i></div>
      <div>
        <span className='ups'>24 hours</span>
        <span>7 days</span>
        <span>30 days</span>
      </div>

      <div className='EchartsBox'>
        <Echarts />
      </div>

    </div>

    <div className='swap_right'>
      <div>
        <h3>Swap</h3>
        <Button onClick={onBSC}>切换BSC</Button>
      </div>
    </div>
    </div>
    

  </div>
}

export default SwapView;