import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select, Space, Upload } from 'antd';
import uploadImg from '../../assets/img/upload.png';
import xImg from '../../assets/img/launch/icon1.png';
import seerImg from '../../assets/img/home/seer.png';
import telImg from '../../assets/img/launch/icon2.png';
import eImg from '../../assets/img/launch/icon5.png';
import gitImg from '../../assets/img/launch/icon3.png';
import meImg from '../../assets/img/home/medium.png';

import '../../assets/css/create.less'
import { useState } from 'react';
import toast from 'react-hot-toast';
import { inputXS } from '../../util/api';

const CreateView = () => {

  const { Dragger } = Upload;
  const { Option } = Select;
  const { TextArea } = Input;

  const [logo, setLogoUrl] = useState<string>();
  const [banner, setBannerUrl] = useState<string>();

  const [pOfTokenFundraising, setpOfTokenFundraising] = useState<string>();
  const [fundingDemand, setfundingDemand] = useState<string>();
  const [totalRaise, settotalRaise] = useState<string>();

  let navigate = useNavigate();

  const onFinish = (values: any) => {
    console.log('Success:', values);
    let socialMedia = JSON.stringify([values.twitter || '', values.seer || '', values.telegram || '', values.medium || '']);
    // console.log(logo)
    // console.log(banner)
    if (!logo) {
      toast.error('Please upload logo!')
      return
    }
    if (!banner) {
      toast.error('Please upload banner!')
      return
    }

    let objData = {
      banner: banner,
      blockChain:values.blockChain,
      email:values.email,
      fundingDemand:values.fundingDemand,
      github:values.github,
      logo:logo,
      socialMedia:socialMedia,
      pOfTokenFundraising:values.pOfTokenFundraising,
      projectName:values.tick,
      totalRaise:values.totalRaise,
      totalSupply:values.totalSupply,
      webSite:values.webSite,
      description:values.description
    }
    console.log(objData);

    navigate('/verify',{state:objData});

  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };


  const logoprops: any = {
    name: 'file',
    multiple: false,
    listType: "picture",
    maxCount: 1,
    onRemove:()=>{
      setLogoUrl('');
    },
    beforeUpload: (file: { type: string; name: any; size: number; }) => {
      const isJpgOrPng = file.type == 'image/jpeg' || file.type == 'image/png';
      if (!isJpgOrPng) {
        toast.error('You can only upload JPG/PNG file!');
        return isJpgOrPng || Upload.LIST_IGNORE;
      }

      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        toast.error('You can only upload 5M!');
        return isLt2M || Upload.LIST_IGNORE;
      }

      if (file) {
        var reader: any = new FileReader();
        reader.readAsDataURL(file); //开始读取文件
        reader.onload = (e: { target: { result: any; }; }) => {
          setLogoUrl(e.target.result);
        }
      }
      return false
    }
  };

  const bannerprops: any = {
    name: 'file',
    multiple: false,
    listType: "picture",
    maxCount: 1,
    onRemove:()=>{
      setBannerUrl('');
    },
    beforeUpload: (file: { type: string; name: any; size: number; }) => {
      const isJpgOrPng = file.type == 'image/jpeg' || file.type == 'image/png';
      if (!isJpgOrPng) {
        toast.error('You can only upload JPG/PNG file!');
        return isJpgOrPng || Upload.LIST_IGNORE;
      }

      const isLt2M = file.size / 1024 / 1024 < 5;
      if (!isLt2M) {
        toast.error('You can only upload 5M!');
        return isLt2M || Upload.LIST_IGNORE;
      }

      if (file) {
        var reader: any = new FileReader();
        reader.readAsDataURL(file); //开始读取文件
        reader.onload = (e: { target: { result: any; }; }) => {
          // alert(e.target.result)
          setBannerUrl(e.target.result);
        }
      }
      return false
    }
  };

  const onvalidator = (item: any) => {
    return (item && item.length > 0) ? Promise.resolve() : Promise.reject('Please input!');
  }

  return <div className='create_box'>
    <div className='content'>
      <div className="tit">
        <h3>Edit information</h3>
      </div>
      <p className='p'>List Project Details, Tell us more about what you're working on</p>

      <div className='create_from'>
        <Form
          // name="basic"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {/* <Form.Item
            name="tick"
            rules={[{ validator: () => onvalidator(tick) }]}
          >
            <div className='c_input'>
              <div>
                <h3>Your projects name?</h3>
                <span>*</span>
              </div>
              <Input placeholder='You project tick' value={tick} onChange={(ev) => { settick((ev.target.value)) }} />
            </div>
          </Form.Item> */}

          <Form.Item
            name="webSite"
            rules={[{ required: true, message: 'Please input your website!' }]}
          >
            <div className='c_input'>
              <div>
                <h3>What's your website?</h3>
                <span>*</span>
              </div>
              <Input placeholder='You website tick' />
            </div>
          </Form.Item>

          <Form.Item
          name="description"
          rules={[{ required: true, message: 'Please input your Description!' }]}
          >
            <div className='c_input'>
              <div>
                <h3>Project Description</h3>
                <span>*</span>
              </div>
              <TextArea rows={4} />
            </div>
          </Form.Item>

          <div className='flex_from'>
          <Form.Item
            name="logo"
            rules={[{ required: true, message: 'Please upload logo!' }]}
          >
            <div className='create_dragger'>
              <div>
                <h3>Logo</h3>
                <span>*</span>
              </div>
              <Dragger {...logoprops}>
                <p className="ant-upload-drag-icon">
                  <img src={uploadImg} alt="" />
                </p>
                <p className="ant-upload-hint">
                  Click to Upload or Drag and Drop <br /> Suggested image size: 800px width, 328px high. Max image size is 5MB.
                </p>
              </Dragger>
              {/* <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> */}
              {/* {logo ? <img src={logo} alt="avatar" style={{ width: '100%',height:'20px' }} />:null} */}
            </div>
          </Form.Item>

          <Form.Item
            name="banner"
            rules={[{ required: true, message: 'Please upload banner!' }]}>
            <div className='create_dragger'>
              <div>
                <h3>Banner</h3>
                <span>*</span>
              </div>
              <Dragger {...bannerprops}>
                <p className="ant-upload-drag-icon">
                  <img src={uploadImg} alt="" />
                </p>
                <p className="ant-upload-hint">
                  Click to Upload or Drag and Drop <br /> Suggested image size: 800px width, 328px high. Max image size is 5MB.
                </p>
              </Dragger>
            </div>
          </Form.Item>
          </div>

          <h3 style={{'paddingBottom':'10px'}}>SOCIAL MEOIR</h3>
          <div className='flex_from'>
            <Form.Item
              name="twitter"
            >
              <div className='c_input c_input_full'>
                <Input placeholder="Your proiect's twitter" prefix={<img src={xImg} />} />
              </div>
            </Form.Item>
            <Form.Item
              name="seer"
            >
              <div className='c_input c_input_full'>
                <Input placeholder="Your proiect's Seer" prefix={<img src={seerImg} />} />
              </div>
            </Form.Item>
            <Form.Item
              name="telegram"
            >
              <div className='c_input c_input_full'>
                <Input placeholder="Your proiect's telegram" prefix={<img src={telImg} />} />
              </div>
            </Form.Item>
            <Form.Item
              name="medium"
            >
              <div className='c_input c_input_full'>
                <Input placeholder="Your proiect's medium" prefix={<img src={meImg} />} />
              </div>
            </Form.Item>
          </div>

          <div className='flex_from'>
            <Form.Item
              name="email"
            >
              <div className='c_input c_input_full'>
                <div>
                  <h3>Email</h3>
                </div>
                <Input placeholder='We will contact you by email' prefix={<img src={eImg} />} />
              </div>
            </Form.Item>
            <Form.Item
              name="github"
            >
              <div className='c_input c_input_full'>
                <div>
                  <h3>GitHUB</h3>
                </div>
                <Input placeholder="Your proiect's github link" prefix={<img src={gitImg} />} />
              </div>
            </Form.Item>
          </div>

          <div className='c_select'>
            <div className='c_tit'>
              <h3>Blockchain Network</h3>
              <span>*</span>
            </div>
            <Form.Item name="blockChain" rules={[{ required: true, message: 'Please Select Blockchain Network!' }]}>
              <Select
                placeholder="Select a option"
                allowClear
              >
                <Option value="harmony">Harmony</Option>
              </Select>
            </Form.Item>
          </div>

          <div className='flex_from'>
            {/* <Form.Item
              name="totalSupply"
              rules={[{ validator: () => onvalidator(totalSupply) }]}
            >
              <div className='c_input c_input_full'>
                <div>
                  <h3>Total supply of Tokens</h3>
                  <span>*</span>
                </div>
                <Input placeholder='' value={totalSupply} onChange={(ev) => { settotalSupply(inputZS(ev.target.value)) }} />
              </div>
            </Form.Item> */}
            <Form.Item
              name="pOfTokenFundraising"
              rules={[{ validator: () => onvalidator(pOfTokenFundraising) }]}
            >
              <div className='c_input c_input_full'>
                <div>
                  <h3>Percentage of token fundraising</h3>
                  <span>*</span>
                </div>
                <Input placeholder='' suffix="%" value={pOfTokenFundraising} onChange={(ev) => { setpOfTokenFundraising(inputXS(ev.target.value)) }} />
              </div>
            </Form.Item>
          </div>

          <div className='flex_from'>
            <Form.Item
              name="fundingDemand"
              rules={[{ validator: () => onvalidator(fundingDemand) }]}
            >
              <div className='c_input c_input_full'>
                <div>
                  <h3>Funding Demand</h3>
                  <span>*</span>
                </div>
                <Input placeholder='' suffix="USDT" value={fundingDemand} onChange={(ev) => { setfundingDemand(inputXS(ev.target.value)) }} />
              </div>
            </Form.Item>
            <Form.Item
              name="totalRaise"
              rules={[{ validator: () => onvalidator(totalRaise) }]}
            >
              <div className='c_input c_input_full'>
                <div>
                  <h3>Total raise</h3>
                  <span>*</span>
                </div>
                <Input placeholder='' suffix="USDT" value={totalRaise} onChange={(ev) => { settotalRaise(inputXS(ev.target.value)) }} />
              </div>
            </Form.Item>
          </div>

          <Form.Item>
            <Space>
              <Button htmlType="button" onClick={() => { navigate(-1) }}>
              Return
              </Button>
              <Button type="primary" htmlType="submit" disabled>
                Next
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </div>
  </div>
}

export default CreateView;