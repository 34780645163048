
import { Button, InputNumber, Modal, Progress, Segmented } from 'antd';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useWeb3Modal } from '@web3modal/ethers/react'
import '../assets/css/detail.less'
import Holders from '../component/Holders';
import Transactions from '../component/Transactions';
import { factoryAdd, getErrorInfo, goAdd, goHash, onFloor, onMomented, signerCall, toBigString, todividedBy, toFormat } from '../util/api';
import { Contract , BrowserProvider , ethers} from 'ethers'
import erc20 from '../util/erc20.json'
import mintImg from '../assets/img/token/mint.svg'
import { chaninArr } from '../util/config';

const Detail = () => {

    const navigate = useNavigate();
    let localtion = useLocation();
    
    const { address , chainId } = useWeb3ModalAccount();
    const { walletProvider }: any = useWeb3ModalProvider();
    const { open } = useWeb3Modal()
    const ethersProvider = new BrowserProvider(walletProvider)

    const [objData, setobjData] = useState<any>(null)
    const [tabs, settabs] = useState<any>('Holders')
    const [isMint, setisMint] = useState<Boolean>(false)
    const [loading, setloading] = useState(false)
    const [MTimes, setMTimes] = useState<any>()
    const [opens, setOpens] = useState(false);
    const [timesVal, settimesVal] = useState()
    const [balance, setbalance] = useState<any>()

    useEffect(() => {
        if (localtion.state) {
            setobjData(localtion.state)
            console.log(localtion.state)
        } 
    }, [])

    useEffect(() => {
        if (chainId && address) {
            if (chainId ==  chaninArr[2].chainId) {
                if ((window as any).FactoryContract && localtion.state) {
                    onMint(localtion.state.tickAddress, address)
                } else {
                    setTimeout(() => {
                        if (localtion.state && (window as any).FactoryContract && address) {
                            onMint(localtion.state.tickAddress, address)
                        }
                    }, 1000)
                }
            } else {
                toast.error('Please refresh the switch chain')
                open()
            }
        }
    }, [address, chainId])

    // 是否开启挖矿
    const onMint = async (tickAddress: any, add: any) => {
        try {
            let res: any = await (window as any).FactoryContract?.allowMint(tickAddress, add);
            setisMint(res)
        } catch (error:any) {
            getErrorInfo(error)
        }
    }
    // 我已经挖矿次数
    const onTimes = async (tickAddress: any, add: any) => {
        try {
            let res: any = await (window as any).FactoryContract?.mintTimeOf(tickAddress, add);
            let num = Number(res)
            setMTimes(num)
        } catch (error:any) {
            getErrorInfo(error)
        }
    }
    // 打开
    const openMint = async() => {
        setOpens(true);
        onTimes(objData.tickAddress, address)
        if(objData.feeToken == '0x0000000000000000000000000000000000000000'){
            let res = await ethersProvider.getBalance(address || '');
            setbalance(ethers.formatEther(res))
        }else if(objData?.fee > 0){
            let signer: any = await signerCall(walletProvider)
            const tokenContract = new Contract(objData?.feeToken, erc20, signer);
            let myBalances = await tokenContract.balanceOf(address);
            if(Number(myBalances) == 0){
                setbalance(0)
            }
            if(myBalances){
                let num = todividedBy(myBalances,Math.pow(10,objData?.feeDecimals))
                setbalance(num)
            }
            
        }
        
    }

    const handleOk = async () => {
        if (!timesVal) {
            toast.error('Please Enter Times')
            return
        }

        if ( Number(balance)  == 0) {
            toast.error('Insufficient balance')
            return
        }

        setloading(true)

        if (objData?.fee == 0) {
            let result = await (window as any).FactoryContract.mint(objData?.tickAddress, timesVal)
            await result.wait()
            toast.success('Mint success')
            setOpens(false)
            return
        }

        if(objData?.timeLimit >0 && objData?.timeLimit - MTimes == 0){
            setloading(false)
            toast.error('Not enough Mint times');
            return
        }

        let valueGas: any = null;
        if (objData.feeToken == '0x0000000000000000000000000000000000000000') {
            try {
                valueGas = toBigString(objData.fee,(timesVal * Math.pow(10, objData.feeDecimals)))
                let result = await (window as any).FactoryContract.mint(objData?.tickAddress, timesVal, { value: valueGas })
                await result.wait()
                toast.success('Mint success')
                setOpens(false)
            } catch (error:any) {
                getErrorInfo(error)
                setloading(false)
            }
            setloading(false)
        } else {
            let signer: any = await signerCall(walletProvider)
            const tokenContract = new Contract(objData?.feeToken, erc20, signer);
            onApprove(tokenContract)
        }
    };

    const onApprove = async (tokenContract: any) => {
        try {
            let allowance = await tokenContract.allowance(address, factoryAdd);
            if (allowance == 0) {
                let approve = await tokenContract.approve(factoryAdd, '1000000000000000000000000');
                approve.wait()
                toast.success('Authorization success')
                setloading(false)
                return
            }

            if (timesVal && parseInt(allowance) > 0 && parseInt(allowance) < (objData.fee * timesVal * Math.pow(10, objData.feeDecimals))) {
                let approve = await tokenContract.approve(factoryAdd, 0);
                approve.wait()
                toast.success('Authorization clearing')
                setloading(false)
                return
            } else {

                if(timesVal && parseFloat(balance) < (objData.fee * timesVal)){
                    toast.error('Insufficient balance')
                    setloading(false)
                    return
                }

                let result = await (window as any).FactoryContract.mint(objData?.tickAddress, timesVal)
                await result.wait()
                toast.success('Mint success')
                setOpens(false)
                setloading(false)
            }
        } catch (error:any) {
            getErrorInfo(error)
            setloading(false)
        }
    }




    return <>
        <div className='content detail_box'>
            <Modal
                title="GatewayX"
                open={opens}
                onOk={handleOk}
                centered={true}
                confirmLoading={loading}
                onCancel={() => { setOpens(false); }}
            >
                <div className='mint_time'>
                    <h3>{objData?.tick}  
                    {objData?.fee != 0 && <span>（available：{(balance == 0?'0':(balance || '--'))} {objData?.feeTokenName}）</span>}
                    </h3>
                    <div className='flex_sb'>
                        <p>Cost</p>
                        <h2>{timesVal ? toBigString(objData?.fee,timesVal) : objData?.fee} {objData?.feeTokenName}</h2>
                    </div>
                    <div className='flex_sb'>
                        <p>Mint Times {objData?.timeLimit != 0 && <i>（Can buy <u style={{ color: '#3766E3' }}>{objData?.timeLimit - MTimes}</u> copies）</i>}</p>
                        <InputNumber
                            min={1}
                            max={objData?.timeLimit != 0 ? (objData?.timeLimit - MTimes) : undefined}
                            value={timesVal}
                            precision={0}
                            onChange={(val: any) => settimesVal(val)}
                        />
                    </div>
                </div>
            </Modal>

            {
                objData && <>
                    <h3>
                        <p onClick={() => navigate(-1)}>← {objData?.projectName}</p>
                        {
                            isMint && <Button type='primary' icon={<Minticon />} size='large' onClick={openMint}>Mint</Button>
                        }
                    </h3>
                    <div className='detail_progress'>
                        <Progress showInfo={false} percent={parseFloat(onFloor(objData?.minted, objData?.supply))} strokeColor={{ '0%': '#108ee9', '100%': '#4c6fff' }} />
                        <p> {onFloor(objData?.minted, objData?.supply)}%</p>
                    </div>
                </>
            }


            <div className='detail_overview'>
                <div className='detail_con'>
                    <h4>Overview</h4>
                    <ul>
                        <li>
                            <p>Tick</p>
                            <div>{(objData?.tick)}</div>
                        </li>
                        <li>
                            <p>Blockchain</p>
                            <div className={objData?.chain=='harmony'?'ha_ups':''}>{(objData?.chain)}</div>
                        </li>

                        <li>
                            <p>Supply</p>
                            <div>{toFormat(objData?.supply)}</div>
                        </li>
                        <li>
                            <p>Limit per mint</p>
                            <div>{toFormat(objData?.max)}</div>
                        </li>
                        <li>
                            <p>Deploy Time</p>
                            <div>{onMomented(objData?.deployTime)}</div>
                        </li>
                        <li className='copyli'>
                            <p>Deploy By</p>
                            <div className='blue' onClick={() => goAdd(objData?.deployBy)}>{objData?.deployBy} <i></i></div>
                        </li>
                        <li className='copyli'>
                            <p>Hash</p>
                            <div className='blue' onClick={() => goHash(objData?.hash)}>{objData?.hash} <i></i></div>
                        </li>
                        <li className='copyli'>
                            <p>Tick Address</p>
                            <div className='blue' onClick={() => goAdd(objData?.tickAddress)}>{objData?.tickAddress} <i></i></div>
                        </li>
                        <li>
                            <p>Mint Gas Token</p>
                            <div>{objData?.feeTokenName} {(objData?.feeToken) != '0x0000000000000000000000000000000000000000' ? `(${objData?.feeToken})` : ''}</div>
                        </li>
                        <li>
                            <p>Mint Gas</p>
                            <div>{objData?.fee}</div>
                        </li>

                        <li>
                            <p>Holders</p>
                            <div>{toFormat(objData?.holders)}</div>
                        </li>
                        <li>
                            <p>Total Transactions</p>
                            <div>{toFormat(objData?.transactions)}</div>
                        </li>
                    </ul>
                </div>
            </div>

            <div className='detail_table'>
                <div className='segmented'>
                    <Segmented
                        options={[
                            {
                                label: (
                                    <div style={{ padding: 4 }}>
                                        <div>Holders</div>
                                    </div>
                                ),
                                value: 'Holders',
                            },
                            {
                                label: (
                                    <div style={{ padding: 4 }}>
                                        <div>Transactions</div>
                                    </div>
                                ),
                                value: 'Transactions',
                            },
                        ]}
                        onChange={(ev) => { settabs(ev) }}
                    />
                </div>
                {
                    tabs == 'Holders' && objData && objData.dId && <Holders dId={objData.dId} />
                }
                {
                    tabs == 'Transactions' && objData && objData.dId && <Transactions dId={objData.dId} />
                }
            </div>
        </div>
    </>;
}

const Minticon = ()=>{
    return <img src={mintImg} alt="" />
}


export default Detail;
