import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom";
import { copyPlaintext, onAdd, onHttp, toFormat } from "../../util/api";
// import badgeImg from '../../assets/img/token/badge.png'
import copyImg from '../../assets/img/token/copy.png'
import avaImg from '../../assets/img/token/avater.png'
import backImg from '../../assets/img/token/back.png'
import toast from "react-hot-toast";
import MarketList from "./MarketList";

const MarketDetail = () => {

  const navigate = useNavigate();
  const localtion = useLocation();

  const [objData, setobjData] = useState<any>(null)
  const [objDetail, setobjDetail] = useState<any>(null)

  useEffect(() => {
    if (localtion.state) {
          setobjData(localtion.state)
          console.log(localtion.state)
          getDetail(localtion.state.tickAddress)
      } 
  }, [])

  const getDetail = async(tick:any)=>{
    let res = await onHttp({token:tick},'/v2/Harmony/MarketDetail')
    if(res?.code == 0){
      setobjDetail(res.data)
    }
  }

  const onCopy = async(url:string)=>{
    let res = await copyPlaintext(url);
    if(res){
      toast.success('copy success!')
    }else{
      toast.error('copy failed!')
    }
  }

  return <div className="marketDetail">
      <div className="made_top">
        <div className="content">
          
          <div className="made_mane">
            <img src={backImg} alt="" className="backimg" onClick={() => navigate(-1)} />
            <img src={avaImg} alt="" />
            <div>
              <h3 className="flex_div">{objData?.tick} 
              {/* <img src={badgeImg} alt="" /> */}
              </h3>
              <h5 className="flex_div">{onAdd(objData?.tickAddress)} <img src={copyImg} alt="" onClick={()=>onCopy(objData?.tickAddress)} /></h5>
            </div>
            
          </div>
          <div>
            <ul className="made_ul">
              <li>
                <h4>{objDetail?.totalSales}</h4>
                <span>Total Sales</span>
              </li>
              <li>
                <h4>${objDetail?.unitPrice}</h4>
                <span>Unit Price</span>
              </li>
              <li>
                <h4>${objDetail?.floorPrice}</h4>
                <span>Floor Price</span>
              </li>
              <li>
                <h4>{toFormat(objDetail?.owners)}</h4>
                <span>Holders</span>
              </li>
              <li>
                <h4>{toFormat(objDetail?.supply)}</h4>
                <span>Total Supply</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
      {
        objData?.dId && <MarketList did={objData.dId} />
      }

  </div>
}

export default MarketDetail;