import React, { useEffect, useState } from 'react';
import { Button, Modal, Space, Table,} from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { getErrorInfo, goAdd, onAdd, onHttp, onMomented } from '../../util/api';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import { useWeb3ModalAccount } from '@web3modal/ethers/react'

interface DataType {
  dId: string;
}

const ProfileTable: React.FC = () => {

  const { address } = useWeb3ModalAccount()
  let navigate = useNavigate();

  const [datas, setDatas] = useState<DataType[]>();
  const [loading, setLoading] = useState(false);
  const [loadingb, setLoadingb] = useState(false);
  
  const [id, setid] = useState<any>(null)

  const [open, setOpen] = useState(false);
  const [modalText, setModalText] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [item, setItem] = useState<any>();
  
  // 预创建
  const onPreDeploy = async(add: any)=>{
    setLoadingb(true)
    let res = await onHttp({address: add},'/v2/Harmony/ContractInfo');
    setLoadingb(false)
    if(res && res.code == 0){
      // setcinfo(res.data)
      // console.log(res.data.bytecode)
      if(address && res.data.bytecode){
        // let args:any = [];
        try {
          setLoadingb(true)
          let result = await (window as any).FactoryContract.preDeploy(res.data.bytecode)
          await result.wait();
          toast.success('PreDeploy Success')
          setLoadingb(false)
          // console.log(result)
          // console.log(result1)
        } catch (error:any) {
          getErrorInfo(error)
          setLoadingb(false)
        }
        
      }
    }
  }

  // 创建
  const onDeploy = async(add:any)=>{
    setLoadingb(true)
    let res = await onHttp({address: add},'/v2/Harmony/ContractInfo');
    setLoadingb(false)
    if(res && res.code == 0){
      // setcinfo(res.data)
      // console.log(res.data.bytecode)
      if(address && res.data.bytecode){
        // let args:any = [];
        try {
          setLoadingb(true)
          let result = await (window as any).FactoryContract.deploy(add,res.data.bytecode)
          await result.wait();
          toast.success('Deploy Success')
          setLoadingb(false)
        } catch (error:any) {
          getErrorInfo(error)
          // toast.error(JSON.stringify(error?.message))
          setLoadingb(false)
        }
      }
    }
  }

  const columns: ColumnsType<DataType> = [
    {
      title: 'Project',
      dataIndex: 'projectName',
    },
    {
      title: 'Time',
      dataIndex: 'deployTime',
      render: (row) => <div>{row==0?'--':onMomented(row)}</div>
    },
    
    {
      title: 'Address',
      dataIndex: 'tickAddress',
      render: (row) => <div className='blue' onClick={()=>goAdd(row)}>{onAdd(row)}</div>
    },
    {
      title: 'State',
      key:'Deploy',
      align: 'right',
      width:130,
      render: (row) => <>
        <Space>
          {row.status == 2 && <Button type='primary' onClick={()=>{setid(row.dId);onPreDeploy(row.tickAddress)}} 
          loading={loadingb && (id == row.dId)}
          >PreDeploy</Button>}
          {row.status == 3 && row.tick == '' &&  <Button type='primary'  onClick={()=>navigate('/inscription',{state:row})}>TokenInfo</Button>}
          {row.status == 3 && row.tick != '' && !row.published && <Button type='primary' loading={loadingb && (id == row.dId)} onClick={()=>{setid(row.dId);onDeploy(row.tickAddress)}} 
          > Deploy</Button>}
          <div>
            {row.status==1 && 'waiting'}
            {row.status==3 && row.published && 'deployed'}
            {row.status==4 && 'refused'}
          </div>
          
        </Space>
      </>,
    },
    {
      title: 'Mint',
      key:'Mint',
      align: 'right',
      width:140,
      render: (row) => <>
        <Space>
          {row.mintStatus == 'waiting' && <Button type='primary' onClick={()=>{
            setItem(row);
            setid(row.dId);
            setOpen(true);
            setModalText('Whether to open Mining')
          }} >Open Mint</Button>}
          {row.mintStatus == 'start' && <Button type='primary' danger onClick={()=>{
            setItem(row)
            setid(row.dId);
            setOpen(true);
            setModalText('Whether to Close Mining')
          }} >Close Mint</Button>}
        </Space>
      </>,
    },
    {
      title: 'Set',
      key:'Operable',
      align: 'right',
      width:100,
      render: (row) => <>
        <Space>
          <Button onClick={()=>navigate('/editinfo')} >Edit Inf</Button>
        </Space>
      </>,
    },
  
  ];

  useEffect(() => {
    if (address) {
      fetchData()
    }
  }, [address])

  const fetchData = async () => {
    setLoading(true);
    let res = await onHttp({
      address: address,
    }, '/v2/Harmony/UserAppList')
    setLoading(false)
    if (res?.code == 0) {
      setDatas(res.data.list);
    }
  };

  // 是否开启挖矿
  const handleOk = async() => {
    try {
      setConfirmLoading(true)
      let result = await (window as any).FactoryContract.setMintFlag(item.tickAddress,item.mintStatus == 'waiting'?1:0)
      await result.wait();
      setOpen(false)
      toast.success('set Success')
    } catch (error:any) {
      getErrorInfo(error)
      // toast.error(JSON.stringify(error?.message))
    }
    setConfirmLoading(false)
  };

  return (
    <div>

      <Modal
        title="Title"
        open={open}
        centered={true}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={()=>setOpen(false)}
      >
        <p>{modalText}</p>
      </Modal>

      <div className='table_div content'>
        <Table
          scroll={{ x: 1000 }}
          columns={columns}
          rowKey={(record) => record.dId}
          dataSource={datas}
          pagination={false}
          loading={loading}
        />
      </div>
    </div>

  );
};

export default ProfileTable;


