import {useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Space } from 'antd';
import xImg from '../../assets/img/launch/icon1.png';
import seerImg from '../../assets/img/home/seer.png';
import telImg from '../../assets/img/launch/icon2.png';
import eImg from '../../assets/img/launch/icon5.png';
import gitImg from '../../assets/img/launch/icon3.png';
import meImg from '../../assets/img/home/medium.png';

import '../../assets/css/create.less'
import { useEffect, useState } from 'react';
import { onHttp } from '../../util/api';

import { useWeb3ModalAccount } from '@web3modal/ethers/react'

const Profiledetail = () => {

  const { TextArea } = Input;
  const { address } = useWeb3ModalAccount()

  let navigate = useNavigate();
  let localtion = useLocation();
  const [objData, setobjData] = useState<any>(null)
  const [cinfo, setcinfo] = useState<any>(null)

  useEffect(() => {
    if (localtion.state && localtion.state.tick) {
      setobjData(localtion.state)
      console.log('localtion.state')
      console.log(localtion.state)
      getContractInfo(localtion.state.tickAddress)
    }
  }, [])

  const onPreDeploy = async()=>{
    if(address){

      let tokendecimals:any = 18;
      console.log('tokendecimals')
      console.log(tokendecimals)

      // let args:any = [
      //   objData.protocol == "ERC20"?0:1,
      //   objData.startHeight,objData.endHeight,objData.feeToken,
      //   (objData.fee/100) * Math.pow(10,tokendecimals),objData.supply * Math.pow(10,objData.decimals),objData.max * Math.pow(10,objData.decimals),objData.decimals,
      //   objData.tick,cinfo.bytecode
      // ];
      
    }
  }
  
  const onDeploy = async()=>{

  }


  const getContractInfo = async(add: any)=>{
    let res = await onHttp({address: add},'/v2/Harmony/ContractInfo');
    if(res && res.code == 0){
      setcinfo(res.data)
    }
    console.log(res)
  }

  return <div className='create_box'>
    <div className='content'>
      <div className='create_from'>
        {
          objData && <Form
            layout="vertical"
          >
            <Form.Item
              name="tick"
            >
              <div className='c_input'>
                <div>
                  <h3>Your projects name</h3>
                </div>
                <Input disabled value={objData.projectName} />
              </div>
            </Form.Item>

            <Form.Item
              name="webSite"
            >
              <div className='c_input'>
                <div>
                  <h3>What's your website</h3>
                </div>
                <Input disabled value={objData.webSite} />
              </div>
            </Form.Item>

            <Form.Item
              name="description"
            >
              <div className='c_input'>
                <div>
                  <h3>Project Description</h3>
                </div>
                <TextArea rows={4} disabled value={objData.description} />
              </div>
            </Form.Item>

            <div className='flex_from'>
              <Form.Item
                name="logo"
              >
                <div className='create_dragger'>
                  <div>
                    <h3>Logo</h3>
                  </div>
                  <img src={objData.logo} alt="avatar" style={{ width: 'auto', height: '40px' }} />
                </div>
              </Form.Item>

              <Form.Item
                name="banner"
              >
                <div className='create_dragger'>
                  <div>
                    <h3>Banner</h3>
                  </div>
                  <img src={objData.banner} alt="banner" style={{ width: 'auto', height: '40px' }} />

                </div>
              </Form.Item>
            </div>

            <h3 style={{ 'paddingBottom': '10px' }}>SOCIAL MEOIR</h3>
            <div className='flex_from'>
              <Form.Item
                name="twitter"
              >
                <div className='c_input c_input_full'>
                  <Input disabled value={JSON.parse(objData.socialMedia)[0]} prefix={<img src={xImg} />} />
                </div>
              </Form.Item>
              <Form.Item
                name="seer"
              >
                <div className='c_input c_input_full'>
                  <Input disabled value={JSON.parse(objData.socialMedia)[1]} prefix={<img src={seerImg} />} />
                </div>
              </Form.Item>
              <Form.Item
                name="telegram"
              >
                <div className='c_input c_input_full'>
                  <Input disabled value={JSON.parse(objData.socialMedia)[2]} prefix={<img src={telImg} />} />
                </div>
              </Form.Item>
              <Form.Item
                name="medium"
              >
                <div className='c_input c_input_full'>
                  <Input disabled value={JSON.parse(objData.socialMedia)[3]} prefix={<img src={meImg} />} />
                </div>
              </Form.Item>

            </div>

            <div className='flex_from'>
              <Form.Item
                name="email"
              >
                <div className='c_input c_input_full'>
                  <div>
                    <h3>Email</h3>
                  </div>
                  <Input disabled value={objData.email} prefix={<img src={eImg} />} />
                </div>
              </Form.Item>
              <Form.Item
                name="github"
              >
                <div className='c_input c_input_full'>
                  <div>
                    <h3>GitHUB</h3>
                  </div>
                  <Input disabled value={objData.github} prefix={<img src={gitImg} />} />
                </div>
              </Form.Item>
            </div>

            <Form.Item
              name="blockChain"
            >
              <div className='c_input'>
                <div>
                  <h3>Blockchain Network</h3>
                </div>
                <Input disabled value={objData.website} />
              </div>
            </Form.Item>

            <div className='flex_from'>
              <Form.Item
                name="totalSupply"
              >
                <div className='c_input c_input_full'>
                  <div>
                    <h3>Total supply of Tokens</h3>
                  </div>
                  <Input disabled value={objData.totalSupply} />
                </div>
              </Form.Item>
              <Form.Item
                name="pOfTokenFundraising"
              >
                <div className='c_input c_input_full'>
                  <div>
                    <h3>Percentage of token fundraising</h3>
                  </div>
                  <Input disabled value={objData.pOfTokenFundraising} suffix="%" />
                </div>
              </Form.Item>
            </div>

            <div className='flex_from'>
              <Form.Item
                name="fundingDemand"
              >
                <div className='c_input c_input_full'>
                  <div>
                    <h3>Funding Demand</h3>
                  </div>
                  <Input suffix="USDT" disabled value={objData.fundingDemand} />
                </div>
              </Form.Item>
              <Form.Item
                name="totalRaise"
              >
                <div className='c_input c_input_full'>
                  <div>
                    <h3>Total raise</h3>
                  </div>
                  <Input suffix="USDT" disabled value={objData.totalRaise} />
                </div>
              </Form.Item>
            </div>

          </Form>
        }

        <div className='inscription_info'>
          <div>
            <h3>Address</h3>
            <p>{objData && objData.deployBy}</p>
          </div>
          <div>
            <h3>By Code</h3>
            <p>{cinfo && cinfo.bytecode}</p>
          </div>
        </div>

        <Space>
          <Button htmlType="button" onClick={() => { navigate(-1) }}>
            Return
          </Button>
          {objData && objData.status == 2 && <Button type="primary" 
          // loading={writePre.isLoading} disabled={!writePre.write} 
          onClick={onPreDeploy}>PreDeploy</Button>}
          {objData && objData.status == 3 && !objData.published && <Button type="primary" 
          // loading={writeDeploy.isLoading} disabled={!writeDeploy.write} 
           onClick={onDeploy}>Deploy</Button>}
          {/* {writePre.isLoading && <div>Check Wallet</div>} */}
          {/* {writeDeploy.isLoading && <div>Check Wallet</div>} */}
        </Space>
      </div>

    </div>
  </div>
}

export default Profiledetail;
