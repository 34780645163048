// import React from 'react';
import { createHashRouter } from "react-router-dom";
import App from './App';
import Launchpad from './pages/Launchpad';
import Home from './pages/Home';
import Detail from './pages/Detail';
import TokenView from "./pages/Token";
import BridgeView from "./pages/Bridge";
import SwapView from "./pages/Swap";
import FarmView from "./pages/Farm";
// import LaunchDetail from "./pages/launch/LaunchDetail";
import CreateView from "./pages/home/CreateView";
import VerifyView from "./pages/home/VerifyView";
import Inscription from "./pages/home/Inscription";
import { AuthRouter } from "./component/Auth";
import Profiledetail from "./pages/token/Profiledetail";
import Profile from "./pages/Profile";
import MarketDetail from "./pages/token/MarketDetail";

const router = createHashRouter([
    {
        path: '/',
        element: <App />,
        children:[
            {
                path: '/',
                element: <Home />,
            },
            // {
            //     path: '/launchdetail',
            //     element: <LaunchDetail />,
            // },
            {
                path: '/launchpad',
                element: <Launchpad />,
            },
            {
                path: '/token',
                element: <TokenView />,
            },
            {
                path: '/detail',
                element: <Detail />,
            },
            {
                path: '/detail/:id',
                element: <Detail />,
            },
            {
                path: '/marketdetail',
                element: <MarketDetail />,
            },
            {
                path: '/marketdetail/:id',
                element: <MarketDetail />,
            },
            
            {
                path: '/profiledetail',
                element: <AuthRouter><Profiledetail /></AuthRouter>,
            },
            {
                path: '/profiledetail/:id',
                element: <AuthRouter><Profiledetail /></AuthRouter>,
            },
            
            {
                path: '/bridge',
                element: <BridgeView />,
            },
            {
                path: '/swap',
                element: <SwapView />,
            },
            {
                path: '/farm',
                element: <FarmView />,
            },
            {   
                path: '/editinfo',
                element: <AuthRouter><CreateView /></AuthRouter>,
            },
            {   
                path: '/verify',
                element: <AuthRouter><VerifyView /></AuthRouter>,
            },
            {   
                path: '/inscription',
                element: <AuthRouter><Inscription /></AuthRouter> ,
            },
            {   
                path: '/profile',
                element:<Profile />,
            },
            
            
        ]
    },
    
]);

export default router;
