import { useEffect, useState } from 'react';
import { Button, Pagination } from 'antd';
import { copyPlaintext, factoryAdd, getErrorInfo, onAdd, onHttp, signerCall, toBigString, toFormat } from '../../util/api';
import toast from 'react-hot-toast';
import copyImg from '../../assets/img/token/copy.png'
import { Contract } from 'ethers'
import { useWeb3ModalProvider , useWeb3ModalAccount } from '@web3modal/ethers/react'
import erc20 from '../../util/erc20.json'

const MarketList = ({did}:any) => {

  const [data, setData] = useState<any>();
  const [loadingb, setLoadingb] = useState(false);
  const [height, setheight] = useState<any>();
  const { walletProvider }: any = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();

  const [params, setParams] = useState<any>({
    limit: 10,
    from: 1
  });
  const [total, setTotal] = useState(0);

  const fetchData = async () => {
    let res = await onHttp({ ...params, did: did }, '/v2/Harmony/MarketListed')
    if (res?.code == 0) {
      setData(res.data.list);
      setTotal(res.data.total)
    }
  };

  const onChange = (val: any) => {
    console.log(val)
    setParams({ ...params, from: (val - 1) * params.limit + 1 });
  }

  useEffect(() => {
    if (did) {
      fetchData();
    }
  }, [JSON.stringify(params), did]);

  const onCopy = async(url:string)=>{
    let res = await copyPlaintext(url);
    if(res){
      toast.success('copy success!')
    }else{
      toast.error('copy failed!')
    }
  }

  const onBuy = async(item:any)=>{
    setLoadingb(true);
    setheight(item.height);
    let valueGas = toBigString(item.totalPrice,Math.pow(10, item.mTokenDecimals));
    if(item.mTokenAddress == '0x0000000000000000000000000000000000000000'){
        try {
          let result = await (window as any).FactoryContract.buy(item.orderId,{value:valueGas})
          await result.wait();
          toast.success('Buy Success')
        } catch (error) {
          getErrorInfo(error)
        }
        setLoadingb(false)
    }else{
      let signer: any = await signerCall(walletProvider)
      const tokenContract = new Contract(item?.mTokenAddress, erc20, signer);
      onApprove(tokenContract,valueGas,item)
    }
  }

  const onApprove = async (tokenContract: any,valueGas:string,item:any) => {
    try {
        let allowance = await tokenContract.allowance(address, factoryAdd);
        if (allowance == 0) {
            let approve = await tokenContract.approve(factoryAdd, '1000000000000000000000000');
            approve.wait()
            toast.success('Authorization success')
            setLoadingb(false)
            return
        }

        if (parseInt(allowance) < parseInt(valueGas)) {
            let approve = await tokenContract.approve(factoryAdd, 0);
            approve.wait()
            toast.success('Authorization clearing')
            setLoadingb(false)
            return
        } else {
            let myBalances = await tokenContract.balanceOf(address);
            if(myBalances < parseInt(valueGas)){
                toast.error('Insufficient balance')
                setLoadingb(false)
                return
            }

            let result = await (window as any).FactoryContract.buy(item.orderId)
            await result.wait();
            toast.success('Buy Success')
            setLoadingb(false)
        }
    } catch (error:any) {
        getErrorInfo(error)
        setLoadingb(false)
    }
  }

  return <div className="collect_box marketList_box content">
    <ul className='collect_ul'>
      {
        data && data.map((item: any, idx: any) => <li key={idx}>
          <h4>{item?.tick} <span>#{item?.height}</span></h4>
          <h2>{toFormat(item?.amt)} <span>${item?.usdPrice} / {item?.tick}</span></h2>
          <div>
            <p>Seller: {onAdd(item?.seller)} <img src={copyImg} alt="" onClick={()=>onCopy(item?.seller)} /></p>
            <p className='blue_p'>Price: {item?.totalPrice} {item?.mTokenName} <i></i></p>
            <p className='blue_p'>${item?.usdTotalPrice}</p>
            <div className='flex_btn'>
              <Button type="primary" size='large' onClick={() =>onBuy(item)} loading={loadingb && (height == item.height)} >Buy</Button>
            </div>
          </div>

        </li>)
      }
    </ul>
    {total > 0 && <Pagination defaultCurrent={1} pageSize={params.limit} total={total} onChange={onChange} />}
    {
      data && data.length == 0 && <div className='nodata'></div>
    }

  </div>



}

export default MarketList;