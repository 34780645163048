import React,{ useEffect, useState } from 'react';
import '../assets/css/token.less'
import searchImg from '../assets/img/search.svg'
import TableView from '../component/TableView';
import MarketTable from './token/MarketTable';

const TokenView = () => {

  const [tabs,settabs] = useState('token')
  const [searchAdd, setsearchAdd] = useState<string>('');
  let ChildRef:any = React.createRef();
  // 智能合约
  useEffect(()=>{
    if(localStorage.getItem('token')){
      let tabsname:string = localStorage.getItem('token') || ''
      settabs(tabsname)
    }
  },[])
  useEffect(()=>{
    if(tabs){
      localStorage.setItem('token',tabs)
    }
  },[tabs])

  const onKeyDown = (event: any) => {
    let e: any = event ? event : (window.event ? window.event : null);
    if (e.keyCode == 13 && searchAdd) {
      ChildRef.current.func(searchAdd,tabs);
    }
  }

  return <div className='tokenBox'>
    <div className='token_top content'>
          <div className='tit'>
            <h3>check out the XRCscription token balance of the address</h3>
          </div>
          
          <div className='input_box'>
              <input type="text" value={searchAdd} onKeyDown={(ev) => onKeyDown(ev)} placeholder='Search by Address / Token' onChange={(ev) => {
                if(!ev.target.value){
                  ChildRef.current.func(null,tabs)
                }
                setsearchAdd(ev.target.value)
              }} />
            <img onClick={()=>{ChildRef.current.func(searchAdd,tabs);}} src={searchImg} alt="search" />
          </div>
    </div>

    <div className='token_tabs content'>
      <div>
        <span onClick={()=>{settabs('token');setsearchAdd('')}} className={tabs =='token'?'ups':''}>Token List</span>
        <span onClick={()=>{settabs('market');setsearchAdd('')}} className={tabs =='market'?'ups':''}>MarketPlace</span>
      </div>
    </div>

    {
      tabs == 'token' && <div>
        <TableView onRef={ChildRef} />
      </div>
    }
    {
      tabs == 'market' && <div className='content'>
        <MarketTable onRef={ChildRef} /> 
      </div>
    }
    
  </div>
}

export default TokenView;