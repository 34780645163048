import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Modal, Radio, Select, Space, Spin, Upload } from 'antd';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getApi, host, onHttp, onHttpA } from '../../util/api';
import { useWeb3ModalAccount } from '@web3modal/ethers/react'


const VerifyView = () => {

  const { Option } = Select;
  const { address } = useWeb3ModalAccount()
  // const [objData, setobjData] = useState<any>();

  const [commitArr, setcommitArr] = useState<Array<string>>([])
  const [commit, setcommit] = useState<any>()
  const [optimization, setOptimization] = useState<any>()
  const [text, settext] = useState<any>()

  const [loading, setLoading] = useState<boolean>(false);
  const [loadingb, setLoadingb] = useState<boolean>(false);
  const [loadingc, setLoadingc] = useState<boolean>(false);


  const [file, setFile] = useState<any>();
  const [open, setOpen] = useState(false);


  const [AbiArr, setAbiArr] = useState<Array<any>>([])
  const [AbiSonArr, setAbiSonArr] = useState<Array<any>>([])
  const [ByteCodeArr, setByteCodeArr] = useState<Array<any>>([])
  const [contractName, setcontractName] = useState<string>()

  const [name, setname] = useState();
  const [tabs, settabs] = useState(3);

  let navigate = useNavigate();
  let localtion = useLocation();

  useEffect(() => {
    getCompiler()
    // if (localtion.state) {
    // setobjData(localtion.state)
    // console.log(localtion.state)
    // }
  }, [])

  // 选择模板
  const onChange = async (e: any) => {
    console.log('radio checked', e.target.value);
    settabs(e.target.value);
    if (e.target.value == 1) {
      onERC('ERC20')
    }
    if (e.target.value == 2) {
      onERC('ERC721')

    }
    if (e.target.value == 3) {
      setLoadingc(false)
      setOptimization(null)
      settext(null)
      setcommit(null)
    }
  };


  const onERC = async (idx: string) => {
    setOptimization('200')
    setLoadingc(true)
    let res: any = await getApi('/v2/Harmony/TemplateSol?t=' + idx)
    setLoadingc(false)
    if (res && res.code == 0) {
      settext(res.data.source)
      setcommit(res.data.compiler)
    }
    console.log(res)
  }

  const getCompiler = async () => {
    let res = await onHttpA({}, '/api/solidity/contract/compilers')
    if (res && res.code == 0) {
      setcommitArr(res.data)
    }
  }


  const onCommitChange = (value: any) => {
    setcommit(value)
  }

  const onOptimizationChange = (value: any) => {
    setOptimization(value || '');
  }



  const props: any = {
    name: 'file',
    multiple: false,
    listType: "text",
    maxCount: 1,
    onRemove: () => {
      // setLogoUrl('');
      settext(null)
      setFile(null)
    },
    // onchange:(info:any)=>{
    //   console.log(info)
    // },
    beforeUpload: (file: any) => {
      console.log(file)
      const isJpgOrPng = file.name.includes('.sol')

      if (!isJpgOrPng) {
        toast.error('You can only upload .sol file!');
        return isJpgOrPng || Upload.LIST_IGNORE;
      }

      if (file) {
        const reader = new FileReader();
        console.log(reader)
        reader.readAsText(file, 'gb2312');
        reader.onload = function (e: any) {//读取完毕从中取值
          // var pointsTxt = 
          // console.log(e.target.result)
          setFile(file)
          settext(e.target.result)
        }
        // reader.readAsText(file);
      }
      return false
    }
  };

  const onVerify = async () => {
    if (!commit) {
      toast.error('please select Compiler')
      return
    }
    if (!optimization) {
      toast.error('please select optimization')
      return
    }
    if (!text) {
      toast.error('please upload file')
      return
    }

    setLoading(true)
    // return
    let res: any = await onCompile();
    setLoading(false)
    if (res && res.code == 0) {
      if (res.data.Error.length > 0) {
        toast.error(JSON.stringify(res.data.Error))
      } else if (res.data.Error.length == 0) {
        toast.success('Verify Success');
        setAbiArr(res.data.AbiArr)
        setByteCodeArr(res.data.ByteCodeArr)
        setOpen(true);
      }
    } else {
      toast.error('Verify Failed')
    }
    console.log('res')
    console.log(res)
  }

  // 编译合约
  const onCompile = async () => {
    var formdata = new FormData();
    formdata.append("compiler", commit);
    formdata.append("optimizer", optimization == '0' ? "0" : "1");/*  */
    formdata.append("runs", optimization);
    formdata.append("type", tabs == 1 ? 'ERC20' : (tabs == 2 ? 'ERC721' : 'custom'));
    if (tabs == 3) {
      formdata.append("files", file);
    }

    let requestOptions: any = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    const response = await fetch(host + "/api/solidity/contract/compile", requestOptions);
    if (response.status == 200) {
      return response.json();
    } else {
      console.log('network error')
    }
  }

  // 获取 bytecode
  const getBytecode = async (params: string) => {
    var formdata = new FormData();
    formdata.append("compiler", commit);
    formdata.append("optimizer", optimization == '0' ? "0" : "1");
    formdata.append("runs", optimization);
    formdata.append("type", tabs == 1 ? 'ERC20' : (tabs == 2 ? 'ERC721' : 'custom'));
    if (tabs == 3) {
      formdata.append("files", file);
    }

    formdata.append("contractName", contractName || '');
    formdata.append("params", params);
    var requestOptions: any = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
    const response = await fetch(host + "/api/solidity/contract/gencode", requestOptions);
    if (response.status == 200) {
      return response.json();
    } else {
      console.log('network error')
    }
  }

  // 选择 合约名称
  const onConnectChange = (val: any) => {
    setcontractName(val);
    let arr = AbiArr.filter(element => {
      return element.contractName == val
    });

    if (arr && arr[0]) {
      let inputArr = JSON.parse(arr[0].abi)

      let arrB = inputArr.filter((element: { type: string; }) => {
        return element.type == "constructor"
      });
      console.log(arrB)
      if (arrB && arrB[0]) {
        setAbiSonArr(arrB[0].inputs)
      } else {
        setAbiSonArr([])
      }
    } else {
      setAbiSonArr([])
    }
  }

  // 提交成功 获取becode
  const onFinish = async (values: any) => {
    if (!name) {
      toast.error('pleace input Project Name')
      return
    }
    if (!contractName) {
      toast.error('pleace select')
      return
    }

    setLoadingb(true)
    // console.log(values)
    let arr: any[] = []
    if (AbiSonArr && AbiSonArr.length > 0) {
      console.log(values)
      AbiSonArr.forEach((_, idx) => {
        arr[idx] = values["index" + idx]
      });
      console.log(arr)
    }

    let res = await getBytecode(JSON.stringify(arr))
    setLoadingb(false)
    console.log(res)
    if (res && res.code == 0) {
      let param = {
        "tickAddress": res.data.contract,
        "projectName": name,
        "protocol": tabs == 1 ? 'ERC20' : (tabs == 2 ? 'ERC721' : ''),
        from:address
      }
      let result = await onHttp(param, '/v2/Harmony/Create2');
      if (result && result.code == 0) {
        toast.success('Created Successfully')
        setTimeout(()=>{
          navigate('/profile', { state: res.data });
        },1500)
      }else{
        toast.error(result?.message || 'failed')
        setLoadingb(false)
      }

    }else{
      toast.error(res?.message || 'failed')
      setLoadingb(false)
    }

  }




  return <div className='create_box verify_box'>
    <div className='content'>
      <div className="tit">
        <h3>Verify contract</h3>
      </div>

      <div className='verify_flex'>
        <p className='p'>COMPILER TYPE AND VERSION SELECTION</p>
        <Radio.Group onChange={onChange} value={tabs}>
          <Radio value={1}><h4>ERC-20</h4></Radio>
          <Radio value={2}><h4>ERC-721</h4></Radio>
          <Radio value={3}><h4>Custom</h4></Radio>
        </Radio.Group>
      </div>

      <Spin spinning={loadingc} tip="Loading...">
        <div className='verify_from'>
          <div className='verify_select'>
            <div>
              <h3>Compiler</h3>
              <Select
                placeholder="Select a option"
                onChange={onCommitChange}
                value={commit}
                allowClear
                disabled={tabs != 3}
              >
                {commitArr.map((item) => <Option key={item} value={item}>{item}</Option>)}

              </Select>
            </div>

            <div className='Optimization'>
              <h3>Optimization</h3>
              <Select
                placeholder="Yes Or No"
                onChange={onOptimizationChange}
                value={optimization}
                allowClear
                disabled={tabs != 3}
              >
                <Option value="0">No</Option>
                <Option value="200">YES</Option>
              </Select>
            </div>

          </div>

          <div className='verify_code'>
            <h3>Contract code</h3>
            <div>
              {/* <input type='file' multiple={false} accept=".sol" /> */}
              {
                tabs == 3 && <Upload {...props}>
                  <Button type="primary">Upload Files</Button>
                </Upload>
              }


              <div>
                {
                  text && <span dangerouslySetInnerHTML={{ __html: text }}></span>
                }
              </div>

              <p>For additional information on Constructor Arguments see Our KB Entry</p>
            </div>
          </div>
          {/* <Space>
          <Button htmlType="button" onClick={() => { navigate(-1) }}>
            Back
          </Button>
          </Space> */}
          <div>
            <Button type="primary" size='large' loading={loading} onClick={() => { onVerify() }}>
              Verify and Publish
            </Button>
          </div>

        </div>
      </Spin>
    </div>

    {/* 弹出框 */}
    <Modal
      open={open}
      title="Verification successful"
      centered={true}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[]}
    >
      <div className='select_connect'>
        <h4>Project Name</h4>
        <Input placeholder='' value={name} onChange={(ev:any)=>{setname(ev.target.value || '')}} />
        <h4>Select Connect</h4>
        <Select
          placeholder="Select a Contract Name"
          onChange={onConnectChange}
          allowClear
        >
          {AbiArr.map((item) => <Option key={item.contractName} value={item.contractName}>{item.contractName}</Option>)}
        </Select>
      </div>

      <div className='input_connect'>
        <Form
          name="basic"
          onFinish={onFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          {
            AbiSonArr.map((item, index) => <Form.Item
              name={"index" + index}
              rules={[
                { required: true, message: 'Please input!' }
              ]}
            >
              <div className=''>
                <h4>{item.name} {`(${item.internalType})`}</h4>
                <Input placeholder='' />
              </div>
            </Form.Item>)
          }
          <Form.Item style={{ 'justifyContent': 'flex-end', 'display': 'flex' }}>
            <Space>
              <Button key="back" onClick={() => setOpen(false)}>Cancle</Button>
              <Button type="primary" htmlType="submit" loading={loadingb} >
                Write
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>

  </div>
}

export default VerifyView;