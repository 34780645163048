import { useEffect, useState } from 'react';
import { Button, Input, Modal, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { getErrorInfo, goAdd, inputZS, onAdd, onHttp, signerCall, toBigString, todividedBy } from '../../util/api';

import { useWeb3ModalAccount,useWeb3ModalProvider } from '@web3modal/ethers/react'
import { Contract} from 'ethers'
import token20 from '../../util/token20.json'
import token721 from '../../util/token721.json'
import toast from 'react-hot-toast';

interface DataType {
  tick: string;
}

const ErcToken = () => {
  const { walletProvider }: any = useWeb3ModalProvider();
  const { address } = useWeb3ModalAccount();

  const [data, setData] = useState<[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const [currentpage,setcurrentpage] = useState(1)
  const [items,setitems] = useState<any>();

  const [params, setParams] = useState<any>({
    limit: 10,
    from: 1,
  });
  const [total, setTotal] = useState(0);
  const [open,setOpen] = useState<boolean>(false)
  const [loadingb, setLoadingb] = useState<boolean>(false);
  const [amount, setamount] = useState<any>();
  const [balance, setbalance] = useState<any>('--');

  const columns: ColumnsType<DataType> = [
    {
      title: 'Tick',
      dataIndex: 'tick',
    },
    {
      title: 'Protocol',
      dataIndex: 'protocol',
    },
    {
      title: 'Token',
      dataIndex: 'tickAddress',
      render: (row) => <div className='blue' title={row} onClick={()=>goAdd(row)}>{onAdd(row)}</div>
    },
    {
      title: 'Operation',
      key: 'operation',
      render: (row) => <>
        <Button type='primary' onClick={()=>onWithdraw(row)} >To Inscription</Button>
      </>
    },
  ];

  const fetchData = async () => {
    setLoading(true);
    let res = await onHttp(params, '/v2/Harmony/Tokens')
    setLoading(false)
    if (res?.code == 0) {
      setData(res.data.list);
      setTotal(res.data.total)
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);

  const handleTableChange: any = (pagination: TablePaginationConfig) => {
    setLoading(true);
    if(pagination.current){
      setcurrentpage(pagination.current)
      setParams({...params,from:(pagination.current - 1) * params.limit + 1});
    }
  };


  const onWithdraw = async(item:any)=>{
    setitems(item)
    setOpen(true)
    setamount('')
    setbalance('--')

    let signer: any = await signerCall(walletProvider)
    let ERCabi:any = item.protocol == 'ERC20'?token20:token721
    const tokenContract = new Contract(item.tickAddress, ERCabi, signer);
    (window as any).tokenContract = tokenContract

    try {
      let myBalances = await tokenContract.balanceOf(address);
      if(Number(myBalances) == 0){
        setbalance(0)
      }
      if(myBalances){
          let num = todividedBy(myBalances,Math.pow(10,item.decimals))
          setbalance(num)
      }
    } catch (error) {
      
    }
    
  }

  const toERC = async()=>{
    if(balance == '--'){
      return
    }
    if(items?.protocol == 'ERC20'){
      if(!amount || amount > parseInt(balance) ){
        toast.error('Please enter correct amount')
        return
      }
      setLoadingb(true)
      try {
        let result = await (window as any).tokenContract.toInscription(toBigString(parseFloat(amount), Math.pow(10, items.decimals)))
        await result.wait();
        toast.success('To Inscription Success')
        setOpen(false)
      } catch (error) {
        getErrorInfo(error)
      }
      setLoadingb(false)
    }

    if(items?.protocol == 'ERC721'){
      if(!amount){
        toast.error('Please enter token id')
        return
      }
      setLoadingb(true)
      try {
        let ids = await (window as any).tokenContract.ownerOf(amount)
        console.log(ids)
        if(ids != address){
          setLoadingb(false)
          toast.error('Invalid Token ID')
          return
        }

        let result = await (window as any).tokenContract.toInscription(amount)
        await result.wait();
        toast.success('To Inscription Success')
        setOpen(false)
      } catch (error) {
        getErrorInfo(error)
      }
      setLoadingb(false)
    }
  }

  return (
    <>
      {/* ERC20 提款 */}
      <Modal
        title={"Assets To Inscription"}
        centered={true}
        open={open}
        onOk={toERC}
        confirmLoading={loadingb}
        onCancel={() => setOpen(false)} >
        <div className='sale_box'>
          <div>
            <div className='sale_tip' style={{'padding':'20px'}}>
              <p>Tick <b>{items?.tick} ({items?.protocol})</b></p>
              <p>Balance <b>{balance}</b></p>
              <p>Token <b>{onAdd(items?.tickAddress)}</b></p>
            </div>
          </div>


        </div>
        <div className='sale_from'>
          <Input placeholder={items?.protocol == 'ERC20'?"Amount":"Token id"} value={amount} onChange={(ev) => setamount(inputZS(ev.target.value))} size="large" />
        </div>
      </Modal>
      <div className='table_div content'>
        <Table
          scroll={{ x: 600 }}
          columns={columns}
          rowKey={(record,idx:any) => idx}
          dataSource={data}
          pagination={{
            current: currentpage,
            pageSize: params.limit,
            total: total,
            position: ['bottomCenter']
          }}
          loading={loading}
          onChange={handleTableChange}
        />
      </div>
    </>
    
  );
};

export default ErcToken;
