import { useEffect, useState } from 'react';
import home1IMG from '../assets/img/home/home1.png'
import bigqIMG from '../assets/img/home/bigq.png'
import bigq1IMG from '../assets/img/home/bigq1.png'
import qiuIMG from '../assets/img/home/qiu.png'
import home2IMG from '../assets/img/home/home2.png'
import home4IMG from '../assets/img/home/home4.png'

import home5IMG from '../assets/img/home/home5.png'
import home6IMG from '../assets/img/home/home6.png'

import work1 from '../assets/img/home/work1.png'
import work2 from '../assets/img/home/work2.png'
import work3 from '../assets/img/home/work3.png'
import work4 from '../assets/img/home/work4.png'
import work5 from '../assets/img/home/work5.png'

import '../assets/css/home.less'
import { useNavigate } from 'react-router-dom';
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import toast from 'react-hot-toast';

const HomeView = () => {

  let navigate = useNavigate();
  const { address } = useWeb3ModalAccount()

  const [imgArr, setimgArr] = useState<Array<any>>([])

  useEffect(() => {
    init()
  }, [])

  const init = () => {
    let arr = []
    for (let i = 0; i < 12; i++) {
      arr.push(require(`../assets/img/home/icon${i + 1}.png`))
    }
    setimgArr(arr)
  }

  return <div className='homeBox'>
    <div className='home_box1'>
      <div className='content'>

        <div className='po_ab home1'>
          <img src={bigqIMG} alt="" />
        </div>
        <div className='po_ab home2'>
          <img src={bigq1IMG} alt="" />
        </div>
        <div className='po_ab home3'>
          <img src={qiuIMG} alt="" />
        </div>
        <div className='po_ab home4'>
          <img src={qiuIMG} alt="" />
        </div>
        <div className='po_ab home5'>
          <img src={qiuIMG} alt="" />
        </div>

        <div>
          <h2>THE NEW ERA OF INSFI FOR INSCRIPTION 2.0</h2>
          <p>AN XRC-20 INSCRIPTION BRIDGING PROTOCOL MADE FOR IIO FAIR LAUNCH & AGGREGATED SWAPPING</p>
          <div className='flex_btn'>
            <button className='ups' onClick={() => {
              if (!address) {
                toast.error('Please connect Wallet')
                return
              }
              navigate('/verify')
            }}>Apply</button>
            <button onClick={() => { toast('Coming Soon') }}>Buy $GX</button>
          </div>

          <div>
            <div className='tit'>
              <h3>What is Gateway X</h3>
            </div>

            <span className='span'>
              GatewayX pioneers the Inscription 2.0 concept, which is based on the XRC-20 protocol
              that deploys pre-generated contracts on Bitcoin L2 & EVM public chains and is
              designed to improve the utility and liquidity of inscriptions. It preserves
              the characteristics of fair sale of inscriptions, while enhancing inscriptions
              with the "Fi" attribute, unleashing inscription liquidity by supporting ERC20,
              ERC721, and ERC1155 standards.
            </span>

            <dl>
              <dt>
                <h4>Our Mission</h4>
                <span>GatewayX develops the next generation of crypto asset issuance solutions.</span>
              </dt>
              <dd>
                <img src={home1IMG} alt="" />
              </dd>
            </dl>
          </div>

        </div>

      </div>

    </div>

    <div className='home_box2'>
      <div className='content'>
        <div className='tit'>
          <h3>BUILD ON OUR XRC-20 Protocol</h3>
        </div>
        <p>Inscriptions have been issued on many chains, but they are
          lacking programmability. We are making inscriptions smart
          through the GatewayX inscription bridge to achieve composability
          and enable more diverse application scenarios. Let’s make
          inscriptions great again!
        </p>
        <img src={home2IMG} alt="" />
      </div>
    </div>

    <div className='home_box3'>
      <div className='content'>
        <div className='tit'>
          <h3>HOW IT WORKS</h3>
        </div>
        <ul>
          <li>
            <div>
              <img src={work1} alt="" />
              <h4>Launchpad</h4>
            </div>
            <p>Our IIO (Initial Inscription Offering) ensures projects are launching fairly without any pre-mining, secret deals, or whales securing a majority of the supply.</p>
            <p>Users earn inscriptions by voting and donating,
              in order to contribute to global web3 open source project,
              provides support and participates in the early stadge of the project.
            </p>
          </li>
          <li>
            <div>
              <img src={work2} alt="" />
              <h4>Users</h4>
            </div>
            <p>Making inscriptions smart with just one click.</p>
            <p>Users can earn inscriptions through voting and donating, in order to contribute,
              provide support and participate in the early stages of their favorite Web3 projects.
            </p>
          </li>
          <li>
            <div>
              <img src={work3} alt="" />
              <h4>Protocol</h4>
            </div>
            <p>GatewayX's XRC-20 protocol is the implementation of the EVM ecosystem’s smart “Inscription 2.0”.
              Its implementation mainly uses Calldata to achieve operationality.</p>
            <p>AMMs are the most basic application of decentralized finance (DeFi). When all
              inscription assets are bridged to GatewayX, all on-chain assets can be operated using Really Contract on the chain, supporting complex contract logic.</p>
          </li>
          <li>
            <div>
              <img src={work4} alt="" />
              <h4>Community</h4>
            </div>
            <p>A place for capturing investment “alpha”</p>
          </li>
          <li>
            <div>
              <img src={work5} alt="" />
              <h4>Infrastructure</h4>
            </div>
            <p>GatewayX is an indexing service and swap aggregator platform designed for inscription holders to help the community thrive and support public chain ecosystems.</p>
          </li>
        </ul>
      </div>
    </div>

    <div className='home_box4'>
      <div className='content'>
        <div className='box4_con'>
          <div className='box4_l'>
            <h3>INSCRIPTION ECONOMICS</h3>
            <p>Powers GatewayX’s entire decentralized application ecosystem.</p>
            <div>Inscription name: <b> $GX</b></div>
            <div>Total issuance:  <b> 10,000,000,000</b> pieces</div>
            <div>Single price:   <b> 0.05USD, 1000</b> pieces per ticket</div>
            <div>Release mechanism: Fair release 80%, team 10% (12-month linear unlock) , Eco-fund 5%, liquidity 5% (locked permanently)</div>
          </div>
          <div className='box4_r'>
            <img src={home4IMG} alt="" />
          </div>
        </div>
        <div className='partnership'>
          <div>
            <h4>Partnership</h4>
            <p>We welcome KOLs with good ideas and communities around the world to join us.</p>
          </div>
          <button onClick={() => {
            toast('Coming Soon')
            return
            if (!address) {
              toast.error('Please connect Wallet')
              return
            }
            navigate('/verify')
          }}>APPLY</button>
        </div>

      </div>
    </div>

    <div className='home_box5'>
      <div className='content'>
        <div className='tit'>
          <h3>WHY CHOOSE US</h3>
        </div>

        <ul>
          <li>
            <div>
              <h4>Security</h4>
            </div>
            <p>Safety is our top priority. Our adoption of strict smart contract 
              standards guarantees greater security and accountability. With 
              GatewayX, you can rest assured that your assets will be protected
               at the highest level possible.</p>
            <img src={home5IMG} alt="" />
          </li>
          <li>
            <div>
              <h4>Transparency</h4>
            </div>
            <p>We will ensure transparency in every transaction; In addition to ensuring that every
              transaction can be queried on-chain, the lock inscription (which is also the original
              asset of the user) is guaranteed to be secure. Therefore, for each inscription,
              its pre-generated contract number is unique.</p>
            <img src={home6IMG} alt="" />
          </li>
        </ul>
      </div>
    </div>

    <div className='home_box6'>
      <div className='content'>
        <div className='tit'>
          <h3>ECOSYSTEM PARTNERS</h3>
        </div>
        <div className='iconbox'>
          <ul>
            {
              imgArr && imgArr.map((item, index) =>
                <li key={index}><img src={item} alt="" /></li>
              )
            }
          </ul>
        </div>

      </div>
    </div>

    <div className='home_box7'>
      <div className='content'>
        <div className='tit'>
          <h3>Start Now</h3>
        </div>
        <p>Find the resources you need to engage with GatewayX</p>

        <ul>
          <li>
            <h4>Document</h4>
            <span>Read a detailed breakdown of our products <br /> and smart contracts</span>
            <button onClick={() => { toast('Coming Soon') }}>Read the document</button>
          </li>
          <li>
            <h4>Tutorials</h4>
            <span>Watch an interactive tutorial to learn <br /> how GatewayX works</span>
            <button onClick={() => { toast('Coming Soon') }}>Go to tutorial</button>
          </li>
        </ul>
      </div>
    </div>

  </div>
}

export default HomeView;