import { useEffect, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { goAdd, onAdd, onHttp} from '../util/api';

interface DataType {
  address: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Address',
    dataIndex: 'address',
    render: (row) => <div className='blue' title={row} onClick={()=>goAdd(row)}>{onAdd(row)}</div>
  },
  {
    title: 'Amount',
    dataIndex: 'amt'
  }
];

const Holders = (props:any) => {
  const [data, setData] = useState<DataType[]>();
  const [loading, setLoading] = useState(false);
  const [currentpage,setcurrentpage] = useState(1)
  const [params, setParams] = useState<any>({
    "from": 1,
    "limit": 10,
    "did": props.dId
  });
  const [total, setTotal] = useState(0);

  const fetchData = async () => {

    setLoading(true);
    let res = await onHttp(params, '/v2/Harmony/Holders')
    setLoading(false)
    if (res && res.code == 0) {
      setData(res.data.list);
      setTotal(res.data.total)
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);

  const handleTableChange: any = (pagination: TablePaginationConfig) => {
    setLoading(true);
    if(pagination.current){
      setcurrentpage(pagination.current)
      setParams({...params,from:(pagination.current - 1) * params.limit + 1});
    }
  };

  return (
    <Table
      scroll={{ x: 300 }}
      columns={columns}
      rowKey={(record) => record.address}
      dataSource={data}
      pagination={{
        current: currentpage,
        pageSize: params?.limit,
        total: total,
        position: ['bottomCenter']
      }}
      loading={loading}
      onChange={handleTableChange}
    />
  );
};

export default Holders;
