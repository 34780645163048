import { Navigate } from "react-router-dom"
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useEffect } from "react"
// import { useAccount } from "wagmi"
 
function AuthRouter( props:any ) {
    // const { address } = useWeb3ModalAccount();
    // const { address } = useWeb3ModalAccount();
    
    // if (address) {
    //     return <></>
    // } else {
    //     return 
    // }
    // useEffect(()=>{
    //     if(address){
            
    //     }
    // },[address])


    return props.children
}


export { AuthRouter }