import { useEffect , useState } from 'react';
import { Button, Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { getErrorInfo, onHttp, onMomented } from '../../util/api';
import { useNavigate } from 'react-router-dom';
import { useWeb3ModalAccount } from '@web3modal/ethers/react'
import toast from 'react-hot-toast';

interface DataType {
  orderId: string;
}



const TableOders = (props:any) => {

  let navigate = useNavigate();
  const { address } = useWeb3ModalAccount();

  const [data, setData] = useState<DataType[]>();
  const [loading, setLoading] = useState(false);
  const [loadingb, setLoadingb] = useState(false);
  const [orderId,setorderId] = useState<any>()

  const [currentpage,setcurrentpage] = useState(1)

  const [params, setParams] = useState<any>({
    limit: 10,
    from: 1,
  });
  const [total, setTotal] = useState(0);

  const columns: ColumnsType<DataType> = [
    // {
    //   title: 'Tick',
    //   dataIndex: 'tick',
    // },
    {
      title: 'Tick',
      key: 'operation',
      align:'left',
      width:220,
      render: (row) => <>
        {row.tick}
        &nbsp;&nbsp;&nbsp;
        {row.status == "selling" && <Button danger type='primary' onClick={()=>onCancel(row)} loading={loadingb && (orderId == row.orderId)} >Cancel</Button>}
      </>
    },
    {
      title: 'Amount',
      dataIndex: 'amt',
    },
    {
      title: 'Quantity',
      key: 'Quantity',
      render: (row) => <div>
        <p>{row.totalPrice} {row.mTokenName}</p>
        <p>${row.usdTotalPrice}</p>
      </div>
    },
    
    {
      title: 'Time',
      dataIndex: 'setupTime',
      render: (row) => <div>{onMomented(row)}</div>
    },
    // {
    //   title: 'Operation',
    //   key: 'operation',
    //   render: (row) => <>
    //     {row.status == "selling" && <Button danger type='primary' onClick={()=>onCancel(row)} loading={loadingb && (orderId == row.orderId)} >Cancel</Button>}
    //   </>
    // },
  ];

  const fetchData = async () => {
    setLoading(true);
    let res = await onHttp({...params,address:address}, '/v2/Harmony/MarketMyOrders')
    setLoading(false)
    if (res?.code == 0) {
      setData(res.data.list);
      setTotal(res.data.total)
    }
  };

  useEffect(() => {
    if(address){
      fetchData();
    }
  }, [JSON.stringify(params),address]);

  const handleTableChange: any = (pagination: TablePaginationConfig) => {
    setLoading(true);
    if(pagination.current){
      setcurrentpage(pagination.current)
      setParams({...params,from:(pagination.current - 1) * params.limit + 1});
    }
  };


  const onCancel = async(item:any)=>{
    setorderId(item.orderId)
    setLoadingb(true)
    try {
      let result = await (window as any).FactoryContract.cancel(item.orderId)
      await result.wait();
      toast.success('Cancel Order Success')
    } catch (error) {
      getErrorInfo(error)
    }
    setLoadingb(false)
  }

  return (
    <>
      <div className='table_div content'>
        <Table
          scroll={{ x: 900 }}
          columns={columns}
          rowKey={(record) => record.orderId}
          dataSource={data}
          pagination={{
            current: currentpage,
            pageSize: params.limit,
            total: total,
            position: ['bottomCenter']
          }}
          loading={loading}
          onChange={handleTableChange}
          // onRow={(record: any) => ({
          //   onClick: () => {
          //     console.log(record)
          //     navigate('/marketdetail/' + record.tick, { state: record })
          //   },
          // })}
        />
      </div>
    </>
    
  );
};

export default TableOders;
