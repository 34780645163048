import moment from "moment"
import BigNumber from "bignumber.js"
import { BrowserProvider, Eip1193Provider, ethers } from 'ethers'
import toast from "react-hot-toast"
import { chaninArr } from "./config"

const headers = {
	'Content-Type': 'application/json'
}

// 测试
export const host = (process.env.NODE_ENV == "development") ? 'http://192.168.0.185:10884' : ''
export const host1 = (process.env.NODE_ENV == "development") ? 'http://192.168.0.185:10880' : ''
export const factoryAdd = (process.env.NODE_ENV == "development") ? '0xD517de3D0C3cBFc3431Fb63b91C5358f1D5daee8' : '0x88d202f3d1092ae46C44DfAaC2A6550679210B7c'
export const contract7 = '0x843D3b2A628aC641785a21B62ebaf866D202f359'     
export const contract4 = "0xb39D3295e22745d541BCbf67A561df2f7D177e9d"


export const getApi = <T>(url: string, init?: RequestInit): Promise<T> => {
	return fetch(host1 + url, init)
		.then(response => {
			// console.log(response)
			if (response.status == 500) {
				// console.log(response.json())
			}
			if (!response.ok) {
				throw new Error(response.statusText)
			}
			return response.json() as Promise<T>
		})
}

export const onHttp = async (parms: any, url: string) => {
	let obj: any = {
		method: 'post',
		headers: headers
	}
	if (parms) {
		obj.body = JSON.stringify(parms)
	}
	try {
		const response = await fetch(host1 + url, obj);
		if (response.status == 200) {
			return response.json();
		} else {
			console.log('network error')
		}
	} catch (err) {
		console.log(err);
	}
}

export const onHttpA = async (parms: any, url: string) => {
	let obj: any = {
		method: 'post',
		headers: headers
	}
	if (parms) {
		obj.body = JSON.stringify(parms)
	}
	try {
		const response = await fetch(host + url, obj);
		if (response.status == 200) {
			return response.json();
		} else {
			console.log('network error')
		}
	} catch (err) {
		console.log(err);
	}
}

export const onAdd = (address: string) => {
	if (address && address.length > 10) {
		return address.slice(0, 4) + '....' + address.substr(-6, 6)
	} else {
		return address
	}
}

export const onMomented = (item: any) => {
	if (item) {
		let time = moment.utc(item * 1000)
		return time.local().format('YYYY-MM-DD HH:mm')
	}
	return ''
}

export async function copyPlaintext(text: string): Promise<boolean> {
	try {
		if (navigator?.clipboard?.writeText) {
			await navigator.clipboard.writeText(text);
			return true;
		} else {
			const textArea = document.createElement("textarea");
			textArea.value = text;

			// Avoid scrolling to bottom
			textArea.style.top = "0";
			textArea.style.left = "0";
			textArea.style.position = "fixed";

			document.body.appendChild(textArea);
			const selection: any = document.getSelection();
			const range = document.createRange();
			// range.selectNodeContents(textArea);
			range.selectNode(textArea);
			selection.removeAllRanges();
			selection.addRange(range);

			const successful = document.execCommand("copy");
			selection.removeAllRanges();
			document.body.removeChild(textArea);
			return successful;
		}
	} catch (e) {
		console.log("copyPlaintext failed", e);
	}
	return false;
}

export const goAdd = (address: string) => {
	let url = chaninArr[2].explorerUrl + '/address/' + address
	window.open(url, "_blank");
}

export const goHash = (hash: string) => {
	let url = chaninArr[2].explorerUrl + '/tx/' + hash
	window.open(url, "_blank");
}

export const onPercent = (num1: any, num2: any, idx: number = 2) => {
	if (num2 == '0') {
		return 100
	}
	let num = parseInt(num1) / parseInt(num2);
	// console.log(idx)
	return Math.floor(num * 100 * Math.pow(10, idx)) / Math.pow(10, idx)
}

export const toFormat = (num: any) => {
	return new BigNumber(num).toFormat()
}
export const toBigString = (num1: any, num2: any) => {
	let num = new BigNumber(num1).multipliedBy(new BigNumber(num2)).toString(10);
	return num
}

export const todividedBy = (num1: any, num2: any) => {
	let num = new BigNumber(num1).dividedBy(new BigNumber(num2)).toFormat();
	return num
}

// 方法
class GatewayXTemplate {
	// 预创建
	public PreCreate(sh: any, eh: any, fee: any, max: any, lim: any, tick: any, dec: any): string {
		return `{"p":"xrc-20","op":"pre-deploy","sh":"${sh}","eh":"${eh}","ft":"${eh}","f":"${fee}","max":"${max}","lim":"${lim}","tick":"${tick}","dec":"${dec}"}`
	}
	// 实际创建
	public Create(addr: string): string {
		return `{"p":"xrc-20","op":"deploy","addr":"${addr}"}`
	}
	// 挖矿
	public Mint(addr: string, amt: string, tick: string): string {
		return `{"p":"xrc-20","op":"mint","addr":"${addr}","amt":"${amt}","tick":"${tick}"}`
	}
	// 卖 
	public Sell(addr: string, amt: string, price: string, tick: string): string {
		return `{"p":"xrc-20","op":"sell","addr":"${addr}","amt":"${amt}","price":"${price}","tick":"${tick}"}`
	}
	// 取消订单 
	public Cancel(addr: string, id: any, tick: string): string {
		return `{"p":"xrc-20","op":"cancel","addr":"${addr}","id":"${id}","tick":"${tick}"}`
	}
	// 买 
	public Buy(addr: string, id: any, tick: string): string {
		return `{"p":"xrc-20","op":"buy","addr":"${addr}","id":"${id}","tick":"${tick}"}`
	}
	// 转帐 
	public Transfer(addr: string, amt: string, to: string, tick: string): string {
		return `{"p":"xrc-20","op":"transfer","addr":"${addr}","amt":"${amt}","to":"${to}","tick":"${tick}"}`
	}

}
export const DotData = new GatewayXTemplate();

// 输入小数 最多八位
export const inputXS = (str: string) => {
	return str.replace(/^\D*(\d*(?:\.\d{0,8})?).*$/g, '$1')
}
// 输入整数
export const inputZS = (str: string) => {
	return str.replace(/[^\d]/g, '')
}
// 不输入中文
export const inputEN = (str: string) => {
	return check(str.replace(/\s*/g, ""))
}
function check(str: string) {
	var temp = ""
	for (var i = 0; i < str.length; i++)
		if (str.charCodeAt(i) > 0 && str.charCodeAt(i) < 255)
			temp += str.charAt(i)
	return temp
}

export const usdtAbi = [
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Approval",
		"type": "event"
	},
	{
		"anonymous": false,
		"inputs": [
			{
				"indexed": true,
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"indexed": true,
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"indexed": false,
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "Transfer",
		"type": "event"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "owner",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			}
		],
		"name": "allowance",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "spender",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "approve",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "who",
				"type": "address"
			}
		],
		"name": "balanceOf",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "decimals",
		"outputs": [
			{
				"internalType": "uint8",
				"name": "",
				"type": "uint8"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "name",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "symbol",
		"outputs": [
			{
				"internalType": "string",
				"name": "",
				"type": "string"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [],
		"name": "totalSupply",
		"outputs": [
			{
				"internalType": "uint256",
				"name": "",
				"type": "uint256"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transfer",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	},
	{
		"inputs": [
			{
				"internalType": "address",
				"name": "from",
				"type": "address"
			},
			{
				"internalType": "address",
				"name": "to",
				"type": "address"
			},
			{
				"internalType": "uint256",
				"name": "value",
				"type": "uint256"
			}
		],
		"name": "transferFrom",
		"outputs": [
			{
				"internalType": "bool",
				"name": "",
				"type": "bool"
			}
		],
		"stateMutability": "nonpayable",
		"type": "function"
	}
]

export const signerCall = async (walletProvider: Eip1193Provider) => {
	// if(!isConnected) throw Error("User disconnected")
	const ethersProvider = new BrowserProvider(walletProvider)
	return await ethersProvider.getSigner()
}

export const onFloor = (num1: any, num2: any) => {
	if (num1 == 0 || num2 == 0) {
		return '0'
	}
	return `${Math.floor((num1 / num2) * 10000) / 100}`
}

export const getlabelhash = (title: string) => {
	let res = ethers.keccak256(title)
	console.log(res)
	return res;
}

export const isAddress = (addr: any) => {
	let res = ethers.isAddress(addr)
	console.log(res, addr)
	return res;
}

export const getErrorInfo = (err: any) => {
	try {
		let str: any = JSON.stringify(err)
		var result = str.substring(str.indexOf("info={"), str.lastIndexOf("}") + 1);
		if (result) {
			let errObj: any = JSON.parse(result)
			toast.error(errObj?.shortMessage)

			console.log(errObj)
		} else {
			toast.error(JSON.stringify(err?.message))
		}
		//console.log(result); // 输出 "}"
	} catch (error) {
		toast.error(JSON.stringify(err?.message))
	}
	// toast.error(JSON.stringify(err))
}

// 切换链
export const switchNetwork = async (walletProvider: any, chainText: any) => {
	try {
		await walletProvider.request({
			method: "wallet_switchEthereumChain",
			params: [{ chainId: chainText.hexId }],
		});
	} catch (switchError: any) {
		// This error code indicates that the chain has not been added to MetaMask.
		if (switchError.code === 4902) {
			try {
				await walletProvider.request({
					method: "wallet_addEthereumChain",
					params: [
						{
							chainId: chainText.hexId,
							chainName: chainText.name,
							rpcUrls: [chainText.rpcUrl],
							blockExplorerUrls: [chainText.explorerUrl],
							nativeCurrency: {
								name: chainText.currency,
								symbol: chainText.currency,
								decimals: chainText.decimals,
							}
						},
					],
				});
			} catch (addError: any) {
				toast.error('Please refresh the switch chain')
				// throw addError;
			}
		}
	}
};

export async function switchChain(web3Provider: any, chainText: any) {
	const provider = web3Provider;
	if (!provider) {
		toast.error("Provider not connected");
		return
	}
	try {
		await provider.send("wallet_switchEthereumChain", [
			{ chainId: chainText.hexId },
		]);
	} catch (error: any) {
		console.log("error.code", (error as any).code);
		if ((error as any).code === 4902) {
			try {
				await provider.send("wallet_addEthereumChain", [{
					chainId: chainText.hexId,
					chainName: chainText.name,
					rpcUrls: [chainText.rpcUrl],
					blockExplorerUrls: [chainText.explorerUrl],
					nativeCurrency: {
						name: chainText.currency,
						symbol: chainText.currency,
						decimals: chainText.decimals,
					}
				}]);
			} catch (err: any) {
				toast.error(err?.message)
			}
		} else {
			console.log(error)
			toast.error(error?.message)
		}
	}
}

