import { useEffect, useImperativeHandle, useState } from 'react';
import { Table } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { onHttp } from '../../util/api';
import { useNavigate } from 'react-router-dom';

interface DataType {
  dId: string;
}

const columns: ColumnsType<DataType> = [
  {
    title: 'Tick',
    dataIndex: 'tick',
  },
  {
    title: 'Floor price',
    dataIndex: 'floorPrice',
  },
  {
    title: '24h volume',
    dataIndex: 'dayVolume',
    render: (row) => {
      return <div>${row}</div>
    }
  },
  {
    title: '24h sales',
    dataIndex: 'daySales',
  },
  {
    title: 'Holders',
    dataIndex: 'owners',
  },
  {
    title: 'Total volume',
    dataIndex: 'totalVolume',
    render: (row) => {
      return <div>${row}</div>
    }
  },
  {
    title: 'Total Sales',
    dataIndex: 'totalSales',
  },
  {
    title: 'Listed',
    dataIndex: 'listed',
  },


];
// const columnsphone: ColumnsType<DataType> = [
//   {
//     title: 'Tick',
//     dataIndex: 'tick',
//   },
//   {
//     title: 'Increase',
//     render: (row) => {
//       return <div className='increase_flex'>
//         <p> {onFloor(row.minted,row.supply)} %</p>
//         <Progress showInfo={false} percent={parseFloat(onFloor(row.minted,row.supply))} strokeColor={{ '0%': '#108ee9', '100%': '#4c6fff' }} />
//       </div>
//     },
//   },
// ];

const TableView = (props:any) => {

  let navigate = useNavigate();

  const [data, setData] = useState<DataType[]>();
  const [loading, setLoading] = useState(false);

  const [currentpage,setcurrentpage] = useState(1)

  const [params, setParams] = useState<any>({
    limit: 10,
    from: 1,
    token: "",
  });
  const [total, setTotal] = useState(0);

  useImperativeHandle(props.onRef, () => {
    // 需要将暴露的接口返回出去
    return {
      func: func,
    };
  });
  function func(sAddr:any,tabs:any) {
    if(sAddr != '' && tabs == 'market'){
      setParams({...params,token:sAddr || ''})
    }
    // console.log('执行我');
  }


  const fetchData = async () => {
    setLoading(true);
    let res = await onHttp(params, '/v2/Harmony/Markets')
    setLoading(false)
    if (res?.code == 0) {
      setData(res.data.list);
      setTotal(res.data.total)
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);

  const handleTableChange: any = (pagination: TablePaginationConfig) => {
    setLoading(true);
    if(pagination.current){
      setcurrentpage(pagination.current)
      setParams({...params,from:(pagination.current - 1) * params.limit + 1});
    }
  };

  return (
    <>
      <div className='table_div content'>
        <Table
          scroll={{ x: 1000 }}
          columns={columns}
          rowKey={(record) => record.dId}
          dataSource={data}
          pagination={{
            current: currentpage,
            pageSize: params.limit,
            total: total,
            position: ['bottomCenter']
          }}
          loading={loading}
          onChange={handleTableChange}
          onRow={(record: any) => ({
            onClick: () => {
              console.log(record)
              navigate('/marketdetail/' + record.tick, { state: record })
            },
          })}
        />
      </div>
    </>
    
  );
};

export default TableView;
