import { useNavigate } from 'react-router-dom';
import xImg from '../assets/img/launch/x.png'
import seerImg from '../assets/img/launch/seer.png'
import bevm from '../assets/img/launch/bevm.png'
import bgs from '../assets/img/launch/bgs.png'
import right from '../assets/img/launch/right.png'
import did from '../assets/img/launch/did.png'
import btcImg from '../assets/img/btc.png'
import idxImg from '../assets/img/launch/index.png'
import idxbg from '../assets/img/launch/idxbg.png'
import { LoadingOutlined } from '@ant-design/icons';

import '../assets/css/launchpad.less'

import { Button, Spin } from 'antd';
import toast from 'react-hot-toast';
import { useEffect, useState } from 'react';
import { contract4, contract7, getlabelhash, switchChain } from '../util/api';
import { BrowserProvider, Contract, ethers } from 'ethers'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { useWeb3Modal } from '@web3modal/ethers/react'
import abi7 from '../util/abi7.json'
import { chaninArr } from '../util/config';

const Launchpad = () => {

  const navigate = useNavigate();
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { open } = useWeb3Modal()
  const { walletProvider }: any = useWeb3ModalProvider();
  const ethersProvider = new BrowserProvider(walletProvider)
  const [searchName, setsearchName] = useState<string>('');
  const [loading, setloading] = useState<boolean>(false)
  const [objData, setobjData] = useState<any>({
    name: ''
  })
  const [state, setstate] = useState<any>('1')
  const [timeObj, setTimeObj] = useState<any>({
    minTime:null,
    maxTime:null
  })

  // useEffect(() => {
  //   if(address){
  //     init()
  //   }
  // }, [address])

  // 初始化
  const init = async () => {
    if ((window as any).Contract7) {
      return
    }
    const ethersProviderA = new ethers.JsonRpcProvider('http://192.168.0.200:9944')
    const Contract7 = new Contract(contract7, abi7, ethersProviderA);
    (window as any).Contract7 = Contract7;
  }

  // 搜索
  const onSearch = async () => {
    // toast.error('coming soon')
    // return
    if (!searchName) {
      toast.error('please enter')
      return
    }
    setloading(true)
    let times = setTimeout(async () => {
      clearTimeout(times)
      try {
        let result = await (window as any).Contract7.available(searchName)
        setloading(false)
        console.log('result', result)
        if (Number(result) == 1 || Number(result) == 3) {
          toast.error('unAvailable');
          return
        }
        if (Number(result) == 2) {
          toast.error('unAvailable');
          return
        }
        setobjData({ ...objData, name: searchName })
        getPreCommit()
      } catch (error: any) {
        setloading(false)
        console.log(error)
        toast.error(error.message)
      }
    }, 500)

  }

  // 请求
  const onRequest = async () => {
    toast.error('coming soon')
    return
    if (isConnected && chainId && chainId != chaninArr[3].chainId) {
      toast('Please switch network');
      switchChain(walletProvider, chaninArr[3])
      return
    }

    const signer = await ethersProvider.getSigner();
    const Contract7 = new Contract(contract7, abi7, signer);
    (window as any).Contract7 = Contract7;

  }

  const getPreCommit = async () => {
    await getTime()
    setloading(true)
    try {
      let labelhash = getlabelhash(searchName)
      alert(labelhash)
      let preCommit = await (window as any).makeCommitmentWithConfig(
        searchName,
        address,
        labelhash,
        contract4,
        address
      )
      console.log('preCommit', preCommit)
      let commitments = await (window as any).commitments(preCommit)
      // this.$loadinga(true)
      if (commitments) {
        let timestamp = new Date().getTime()
        // console.log('timestamp')
        // console.log('当前时间',new Date(timestamp))
        // console.log('提交时间',new Date(parseInt(commitments+'000')))
        // console.log('commitments',commitments)
        // console.log((timestamp - parseInt(commitments+'000'))/60000)
        let times = timestamp - parseInt(commitments + '000')
        let minTime = timeObj.minTime * 1000
        let maxTime = timeObj.maxTime * 1000
        console.log(minTime, maxTime, 'maxTime')
        if (commitments == '0') {
          setstate('1')
        } else if (times < minTime && commitments != '0') {
          setstate('2')
        } else if (times > minTime && times < maxTime) {
          setstate('3')
        } else if (times > maxTime) {
          setstate('1')
        }
      } else {
        setstate('1')
      }
      setloading(false)
    } catch (err:any) {
      toast.error(err?.message)
      setloading(false)
    }
  }

  const getTime = async () => {
    try {
      let minTime = await (window as any).Contract7.minCommitmentAge()
      let maxTime = await (window as any).Contract7.maxCommitmentAge()
      console.log(Number(minTime))
      console.log(Number(maxTime))
    } catch (err: any) {
      toast.error(err.message)
    }

  }

  return <div className='Launchpad_box'>

    <div className='Launchpad_bg'>
      <div className='content'>
        <div>
          <h2>BEVM-BNS</h2>
          <div className='links flex'>
            {/* <div className='flex'> */}
            {/* <a href="https://twitter.com/Onescription" target={'_blank'} ><img src={xImg} alt="" /></a>
              <a href="https://to.seer.eco/#/#ONES@Harmony:genesis.seer.eco" target={'_blank'} ><img src={seerImg} alt="" /></a> */}
            {/* </div> */}
            <div className='insfi'>
              <img src={bevm} alt="" />
              <i>BNS</i>
            </div>
          </div>
          <h3>The First Ever .BTC Domain Issued on Bitcoin L2 Network</h3>
          <p>Once you obtain it, you can have it for a lifetime.</p>
        </div>
        <img src={bgs} alt="" />
      </div>
    </div>

    <div className='Launchpad_con'>
      <div className='tits'>
        <h3>Live and Upcoming Projects on GatewayX</h3>
        <p>These projects are not part of the incubation roster. View descriptions and participate sales.</p>
      </div>

      <div className='did_box content'>

        <div className='did_lefe'>
          <Spin indicator={<LoadingOutlined style={{ fontSize: 36 }} spin />} spinning={loading} >
            <div className='input_did'>
              <input type="text" value={searchName} placeholder='Search By Domain' onChange={(ev) => {
                setsearchName(ev.target.value)
              }} />
              <button onClick={onSearch}>
                <img src={right} alt="" />
                Search
              </button>
            </div>
            <div className='con_did'>
              <div className='con_did_left'>
                <div>
                  <img src={did} alt="" />
                  <span>{objData.name || 'xxx'}.BTC</span>
                  <p>{objData.name || 'xxx'}.BTC</p>
                </div>
              </div>
              <div className='con_did_right'>
                <h4>Register your domain in 3 steps</h4>
                <dl>
                  <i></i>
                  <dt>1</dt>
                  <dd>
                    <h5>Request to register</h5>
                    <p>You will be asked to submit the registration request. Your request should be processed within 7 days.
                      Otherwise, you will have to resubmit the registration request again.</p>
                  </dd>
                </dl>
                <dl>
                  <i></i>
                  <dt>2</dt>
                  <dd>
                    <h5>Wait for 20 seconds</h5>
                    <p>The waiting time is to prevent other parties from front-running you in the domain registration.</p>
                  </dd>
                </dl>
                <dl>
                  <dt>3</dt>
                  <dd>
                    <h5>Confirm your registration</h5>
                    <p>You will obtain the domain once this transaction is completed.</p>
                  </dd>
                </dl>
              </div>
            </div>
          </Spin>
        </div>

        <div className='did_right'>
          <h3>{objData.name || 'xxx'}.BTC</h3>
          <div className='line'></div>
          <div className='flex_div'>
            <p>Gas Token</p>
            <p><img src={btcImg} alt="" /> BTC</p>
          </div>
          <div className='flex_div'>
            <p>Registration Period</p>
            <p><i>99</i> YEARS</p>
          </div>
          <div className='flex_div'>
            <p>Registration Price</p>
            <p><i>5</i> USDT</p>
          </div>
          <div className='flex_div'>
            <p>Gas Price</p>
            <p><i>0.005</i> USDT</p>
          </div>
          <div className='flex_div'>
            <p>Tax</p>
            <p><i>0</i> USDT</p>
          </div>
          <div className='line'></div>
          <div>
            <p>Registration rules:</p>
            <span>
              3 characters: 100u <br />
              4 characters: 10u <br />
              5 characters: 5u <br />
              6 characters and above, free,
            </span>
          </div>
          <Button onClick={onRequest} disabled={!isConnected}>Request</Button>
        </div>
      </div>


      <div className='did_text content'>
        <div>
          <h4>Is Bitcoin Name Service (BNS) necessary?</h4>
          <div className='flex_did'>
            <div>
              <p>
                <ImgView idx={1} />
                <span>The Bitcoin Domain Name Service (BNS) based on the BEVM public chain enables cryptocurrency users to have easily identifiable and readable .BTC domain names associated with their Web3 wallets, thereby improving the user-friendliness of the Bitcoin Web3 experience.</span>
              </p>
              <p>
                <ImgView idx={2} />
                <span>The Bitcoin domain name service has its own hype attributes, especially the 3-digit, 4-digit, and 5-digit high-quality names, which often increase hundreds of times, have great fomo and hype effects, and are also one of the hot topics in web3.</span>
              </p>
            </div>
            <img src={idxbg} alt="" className='flex_img' />
          </div>
          <div className='line'></div>
          <h4>How Does A .BTC Domain Work?</h4>
          <div className='flex_did_p'>
            <p>
              <ImgView idx={1} />
              <span>A .BTC domain is a specific type of blockchain domain name that is registered as an NFT and stored on the Bitcoin network.</span>
            </p>
            <p>
              <ImgView idx={2} />
              <span>The .BTC domain name is stored on the BNS protocol and registered through smart contracts on the BEVM public chain. These domains leverage Bitcoin's security features and implement smart contract languages such as Solidity, Rust, and Move to create decentralized name registries.</span>
            </p>
            <p>
              <ImgView idx={3} />
              <span>The ownership of each .BTC domain is recorded on the blockchain in the form of a hash. Each domain and subdomain on BNS has a decentralized identifier (DID), providing a permanent identifier for the public key and is compatible with other systems that use DID.</span>
            </p>
            <p>
              <ImgView idx={4} />
              <span>.BTC domain names are organized into a three-tiered global name hierarchy; namespaces, BNS names, and BNS subdomains.</span>
            </p>
            <p>
              <ImgView idx={5} />
              <span>Namespaces are similar to DNS top-level domains; they are names at the top of the hierarchy that anyone can create but cannot own.</span>
            </p>
            <p>
              <ImgView idx={6} />
              <span>BNS names are recorded directly on the blockchain and their creation depends on a namespace containing already existing BNS names.</span>
            </p>
            <p>
              <ImgView idx={7} />
              <span>BNS subdomain records are stored off-chain and owned by a separate private key.</span>
            </p>
            <p>
              <ImgView idx={8} />
              <span>You can register the .BTC domain name by conducting transactions through the BNS smart contract on the BEVM public chain. Smart contracts ensure that the BNS domain is synchronized and replicated to all other Stacks nodes around the world.</span>
            </p>
          </div>
          <div className='line'></div>
          <h4>How to buy a Bitcoin domain?</h4>
          <div className='flex_did_p'>
            <p>
              <ImgView idx={1} />
              <span>Before obtaining a .BTC domain name, you need a wallet that supports the BEVM public chain.</span>
            </p>
            <p>
              <ImgView idx={2} />
              <span>You need to swap Bitcoin onto BEVM in your wallet as a minting & gas fee to purchase a Bitcoin domain name.We recommend OKX Wallet and Unisat Wallet to complete the swap via the bridge. </span>
            </p>
            <p>
              <ImgView idx={3} />
              <span>Once you complete the swap, the steps to purchase a Bitcoin domain are as follows:</span>
            </p>
            <div className='did_yuan'>
              <p>
                Link your Wallet to GatewayX: <br />
                <span>Link any wallets that support BEVM Chain to the website to log in. We recommend: OKX Wallet, MetaMask, and TokenPocket.</span>
              </p>
              <p>
                Search for your .BTC name:<br />
                <span>Go to https://gwx.one and enter the name you want to register in the search bar. A pop-up window will tell you whether the name is available and you need to ensure that there is a certain fee.</span>
              </p>
              <p>
                Confirm transaction: <br />
                <span>This transaction will incur a small fee. Once the transaction is completed, the BTC domain name will appear in your wallet. And it’s free to renew for a  lifetime.</span>
              </p>
              <p>
                Secondary purchase:  <br />
                <span>You can also purchase second-hand BTC domain names on the marketplaces. BNS domains provide people with a unique web3 identity and make it easier to complete transactions without any errors.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}

export default Launchpad;

const ImgView = (props: any) => {
  return <i className='did_idx'>
    <span>{props.idx || 1}</span>
    <img src={idxImg} />
  </i>
}

