import { useEffect, useImperativeHandle, useState } from 'react';
import { Progress, Table, Tag } from 'antd';
import type { ColumnsType, TablePaginationConfig } from 'antd/es/table';
import { onFloor, onHttp } from '../util/api';
import { useNavigate } from 'react-router-dom';
import harmonyImg from '../assets/img/harmony.svg'

interface DataType {
  dId: string;
}

// interface TableParams {
//   pagination?: TablePaginationConfig;
//   sortField?: string;
//   sortOrder?: string;
//   filters?: Record<string, FilterValue>;
// }

const columns: ColumnsType<DataType> = [
  // {
  //   title: 'Project',
  //   dataIndex: 'projectName',
  //   width: 150,
  //   render: (row) => <div style={{'whiteSpace': 'nowrap'}}>{row}</div>
  // },
  {
    title: 'Tick',
    width:200,
    render: (row) => {
      return <div className='flex_img'><img src={row.logo} alt="" /> {row.tick}</div>
    }
  },
  {
    title: 'Total raise',
    dataIndex: 'totalRaise',
  },
  {
    title: 'Token price',
    dataIndex: 'price',
    render: (row) => {
      return <div>${row}</div>
    }
  },
  {
    title: 'Chain',
    dataIndex: 'chain',
    render: (row) => {
      return <div className='flex_img'>{row == 'harmony' && <img src={harmonyImg} alt="" />} {row}</div>
    }
  },
  {
    title: 'Increase',
    render: (row) => {
      return <div className='increase_flex'>
        <p> {onFloor(row.minted,row.supply)} %</p>
        <Progress showInfo={false} percent={parseFloat(onFloor(row.minted,row.supply))} strokeColor={{ '0%': '#108ee9', '100%': '#4c6fff' }} />
      </div>
    },
  },
  {
    title: 'ROI (ATH)',
    dataIndex: 'rol',
    render: (row) => {
      return <Tag color="green">{row} X</Tag>
    }
  }
  

];
const columnsphone: ColumnsType<DataType> = [
  {
    title: 'Tick',
    dataIndex: 'tick',
  },
  {
    title: 'Increase',
    render: (row) => {
      return <div className='increase_flex'>
        <p> {onFloor(row.minted,row.supply)} %</p>
        <Progress showInfo={false} percent={parseFloat(onFloor(row.minted,row.supply))} strokeColor={{ '0%': '#108ee9', '100%': '#4c6fff' }} />
      </div>
    },
  },
];

const TableView = (props:any) => {

  let navigate = useNavigate();

  const [data, setData] = useState<DataType[]>();
  const [loading, setLoading] = useState(false);

  const [currentpage,setcurrentpage] = useState(1)

  const [params, setParams] = useState<any>({
    limit: 10,
    from: 1,
    wd: "",
  });
  const [total, setTotal] = useState(0);

  useImperativeHandle(props.onRef, () => {
    // 需要将暴露的接口返回出去
    return {
      func: func,
    };
  });
  function func(sAddr:any,tabs:any) {
    
    if(sAddr != '' && tabs == 'token'){
      // alert(sAddr)
      setParams({...params,wd:sAddr || ''})
    }
    // console.log('执行我');
  }

  // const [tableParams, setTableParams] = useState<any>({
  //   pagination: {
  //     current: 1,
  //     pageSize: 10,
  //     position:['bottomCenter']
  //   },
  // });

  const fetchData = async () => {
    setLoading(true);
    let res = await onHttp(params, '/v2/Harmony/List')
    setLoading(false)
    if (res?.code == 0) {
      setData(res.data.list);
      setTotal(res.data.total)
    }
  };

  useEffect(() => {
    fetchData();
  }, [JSON.stringify(params)]);

  const handleTableChange: any = (pagination: TablePaginationConfig) => {
    setLoading(true);
    if(pagination.current){
      setcurrentpage(pagination.current)
      setParams({...params,from:(pagination.current - 1) * params.limit + 1});
    }
    // `dataSource` is useless since `pageSize` changed
    // if (pagination.pageSize != currentpage) {
    //   setData([]);
    // }
  };

  

  return (
    <>
      <div className='table_div content web'>
        <Table
          scroll={{ x: 1000 }}
          columns={columns}
          rowKey={(record) => record.dId}
          dataSource={data}
          pagination={{
            current: currentpage,
            pageSize: params.limit,
            total: total,
            position: ['bottomCenter']
          }}
          loading={loading}
          onChange={handleTableChange}
          onRow={(record: any) => ({
            onClick: () => {
              console.log(record)
              navigate('/detail/' + record.tick, { state: record })
            },
          })}
        />
      </div>
      <div className='table_div content phone'>
        <Table
          scroll={{ x: 300 }}
          columns={columnsphone}
          rowKey={(record) => record.dId}
          dataSource={data}
          pagination={{
            current: currentpage,
            pageSize: params.limit,
            total: total,
            position: ['bottomCenter']
          }}
          loading={loading}
          onChange={handleTableChange}
          onRow={(record: any) => ({
            onClick: () => {
              console.log(record)
              navigate('/detail/' + record.tick, { state: record })
            },
          })}
        />
      </div>
    </>
    
  );
};

export default TableView;
