import { useEffect, useState } from 'react';

// import * as echarts from 'echarts/lib/echarts.js'
import * as echarts from 'echarts' 
import ReactEcharts from "echarts-for-react";

const SpaceEcharts = ()=>{
    const [option,setoption] = useState({})

    useEffect(()=>{

            setoption({
                tooltip: {
                    trigger: 'axis'
                },
                grid: {
                  top:"20px",
                  left:"0",
                  right:"0",
                  bottom:"30px",
                  containLabel: false
                },
                xAxis: {
                    type: 'category',
                    data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
                    axisTick:{
                      "show":false //隐藏x轴刻度
                    },
                    axisLine:{     
                      "show":false  //隐藏x轴线
                    },
                    // show:false,
                },
                yAxis: {
                  type: 'value',
                  // min:'dataMin',
                  // max:'dataMax',
                  show:false,
                  // splitNumber:2
                },
                series: [
                  {
                    data: [820, 932, 901, 934, 1290, 1330, 1320],
                    type: 'line',
                    smooth: true,
                    showSymbol: true,
                    itemStyle: {
                        color: '#FF9364'
                      },
                      areaStyle: {
                        color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                          {
                            offset: 0,
                            color: 'rgba(255, 147, 100, 0.5)'
                          },
                          {
                            offset: 1,
                            color: 'rgba(242, 95, 51, 0.01)'
                          }
                        ])
                      }
                    // areaStyle: {
                    //     opacity: 0.1,
                    //     color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                    //       {
                    //         offset: 0,
                    //         color: '#34C759'
                    //       },
                    //       {
                    //         offset: 1,
                    //         color: '#24C759'
                    //       }
                    //     ])
                    // },
                  }
                ]
            });
        
    },[])

    return (
        <ReactEcharts option={option} />
    );
}

export default SpaceEcharts;