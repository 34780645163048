import { Link, NavLink, useLocation, useNavigate } from 'react-router-dom';
import logo from '../assets/img/logo.png';
import telImg from '../assets/img/seer.svg';
import xImg from '../assets/img/ximg.svg';
import menuImg from '../assets/img/menu.png';

import '../assets/css/head.less'
import { useWeb3Modal } from '@web3modal/ethers/react'
import { onAdd , switchNetwork , switchChain } from '../util/api';
import toast from 'react-hot-toast';
import { Drawer } from 'antd';
import { useEffect, useState } from 'react';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { chaninArr } from '../util/config';

const Hedaer = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const { open } = useWeb3Modal()
  const { address, chainId, isConnected } = useWeb3ModalAccount()
  const { walletProvider }:any = useWeb3ModalProvider()
  const [openPH, setOpenPH] = useState(false);

  useEffect(()=>{
    if(isConnected && chainId && chainId != chaninArr[2].chainId){
      // alert(location.pathname)
      if(location.pathname == '/bridge' || location.pathname == '/launchpad'){
        return
      }
      switchChain(walletProvider,chaninArr[2])
    }
  },[isConnected])

  useEffect(()=>{
    if(address){
      let addr = localStorage.getItem('address') || null
      if(addr){
        if(addr != address){
          localStorage.setItem('address',address)
          navigate(0)
        }
      }else{
        localStorage.setItem('address',address)
      }
    }
  },[address])

  const showDrawer = () => {
    setOpenPH(true);
  };

  const onClose = () => {
    setOpenPH(false);
  };

  const showToken = () => {
    return (location.pathname.includes('detail'))
  }
  const showHome = () => {
    return (location.pathname == '/verify')
  }

  const showLaunch = () => {
    return (location.pathname == '/launchdetail')
  }

  const showProfile = () => {
    return (location.pathname == '/inscription' || location.pathname == '/editinfo' )
  }

  return <div className='header_box'>
    <ScrollToTop></ScrollToTop>
    <div className='flex_sb'>
      <div>
        <Link to="/" ><img src={logo} alt="logo" /></Link>

        <div className='web menu_con'>
          <ul>
            <li>
              <NavLink className={({ isActive }) => { return (isActive || showHome()) ? "ups" : ""; }} to="/">Home</NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => { return (isActive || showLaunch()) ? "ups" : ""; }} to="/launchpad">Launchpad</NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => { return (isActive || showToken()) ? "ups" : ""; }} to="/token">Token</NavLink>
              {/* <a>Token</a> */}
            </li>
            <li className='lino'>
              <NavLink className={({ isActive }) => { return isActive ? "ups" : ""; }} to="/bridge">Bridge</NavLink>
              {/* <a>Bridge</a> */}
            </li>
            <li className='lino'>
              {/* <NavLink className={({ isActive }) => { return isActive ? "ups" : ""; }} to="/swap">Swap</NavLink> */}
              <a>Swap</a>
            </li>
            <li className='lino'>
              {/* <NavLink className={({ isActive }) => { return isActive ? "ups" : ""; }} to="/farm">Farm</NavLink> */}
              <a>Farm</a>
            </li>
            <li>
              <NavLink className={({ isActive }) => { return (isActive || showProfile()) ? "ups" : ""; }} to="/profile">Profile</NavLink>
            </li>
          </ul>
        </div>
      </div>

      <div>
        <div className='head_icon'>
          <a href="https://twitter.com/GatewayX_one" target={'_blank'} ><img src={xImg} alt="logo" /></a>
          {/* <a href="https://docs.gwx.one/" target={'_blank'}><img src={bookImg} alt="logo" /></a> */}
          <a href="https://to.seer.eco/#/#gatewayx1:genesis.seer.eco" target={'_blank'} ><img src={telImg} alt="logo" /></a>
        </div>
        <div className='wallet_box'>
          <button onClick={() => open()} className={address && 'ava_btn'}>
            {address ? <div className='avater'><i></i>{onAdd(address)}</div> : 'Connect Wallet'}
          </button>
        </div>
        <div className='phone_menu phone'>
          <img src={menuImg} alt="" onClick={showDrawer} />
        </div>
      </div>

      <Drawer placement="right" open={openPH} closable={false} onClose={onClose} width={360}>
        <div className='menu_phone_box'>
          <div className='menu_close' onClick={onClose}>
            ×
          </div>
          <div className='menu_con'>
          <ul onClick={onClose}>
            <li>
              <NavLink className={({ isActive }) => { return (isActive || showHome()) ? "ups" : ""; }} to="/">Home</NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => { return (isActive || showLaunch()) ? "ups" : ""; }} to="/launchpad">Launchpad</NavLink>
            </li>
            <li>
              <NavLink className={({ isActive }) => { return (isActive || showToken()) ? "ups" : ""; }} to="/token">Token</NavLink>
              {/* <a>Token</a> */}
            </li>
            <li>
              <NavLink className={({ isActive }) => { return isActive ? "ups" : ""; }} to="/bridge">Bridge</NavLink>
              {/* <a>Bridge</a>  */}
            </li>
            <li onClick={() => { toast('Coming Soon') }}>
              {/* <NavLink className={({ isActive }) => { return isActive ? "ups" : ""; }} to="/swap">Swap</NavLink> */}
              <a>Swap</a>
            </li>
            <li onClick={() => { toast('Coming Soon') }}>
              {/* <NavLink className={({ isActive }) => { return isActive ? "ups" : ""; }} to="/farm">Farm</NavLink> */}
              <a>Farm</a>
            </li>
            <li>
              <NavLink className={({ isActive }) => { return (isActive || showProfile()) ? "ups" : ""; }} to="/profile">Profile</NavLink>
            </li>
          </ul>
          <div className='head_icon'>
            <a href="https://twitter.com/GatewayX_one" target={'_blank'} ><img src={xImg} alt="logo" /></a>
            <a href="https://to.seer.eco/#/#gatewayx1:genesis.seer.eco" target={'_blank'} ><img src={telImg} alt="logo" /></a>
          </div>
          </div>
        </div>
      </Drawer>
    </div>

  </div>
}

const ScrollToTop = (props: any) => {

  const { pathname } = useLocation();

  useEffect(() => {

    window.scrollTo(0, 0)

  }, [pathname]);

  return props.children;

}

export default Hedaer;

