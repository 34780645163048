import { useEffect, useState } from 'react';
import { Button, Input, Modal, Pagination, Select } from 'antd';
import { copyPlaintext, factoryAdd, getErrorInfo, inputXS, inputZS, isAddress, onAdd, onFloor, onHttp, signerCall, toBigString, todividedBy } from '../../util/api';

import toast from 'react-hot-toast';
import reImg from '../../assets/img/token/refresh.png'
import copyImg from '../../assets/img/copy.png'
import teImg from '../../assets/img/token/token.png'
import { useWeb3ModalAccount,useWeb3ModalProvider } from '@web3modal/ethers/react'
import { Contract} from 'ethers'
import token20 from '../../util/token20.json'
import token721 from '../../util/token721.json'
import BigNumber from 'bignumber.js';

const Collect = () => {

  const { walletProvider }: any = useWeb3ModalProvider();

  const { Option } = Select;
  const { address } = useWeb3ModalAccount()
  const [data, setData] = useState<any>();
  const [loading, setLoading] = useState(false);

  const [loadingb, setLoadingb] = useState(false);
  const [loadingc, setLoadingc] = useState(false);

  const [open, setOpen] = useState(false);
  const [openA, setOpenA] = useState(false);
  
  const [items, setitems] = useState<any>();
  const [receiverAdd, setReceiverAdd] = useState<any>();
  const [amont, setAmont] = useState<any>();
  const [list, setList] = useState<any>();
  const [idx, setidx] = useState<any>(null);
  const [price, setprice] = useState<any>();
  const [amount, setamount] = useState<any>();

  const [openT, setOpenT] = useState(false);
  const [loadingt, setLoadingt] = useState(false);
  const [balance, setbalance] = useState<any>('--')


  const [params, setParams] = useState<any>({
    limit: 10,
    from: 1
  });
  const [total, setTotal] = useState(0);

  const fetchData = async () => {
    setLoading(true);
    let res = await onHttp({ ...params, address: address }, '/v2/Harmony/Address')
    setLoading(false)
    if (res?.code == 0) {
      setData(res.data.list);
      setTotal(res.data.total)
    }
  };

  const onChange = (val: any) => {
    console.log(val)
    setParams({ ...params, from: (val - 1) * params.limit + 1 });
  }

  useEffect(() => {
    if (address) {
      fetchData();
    }
  }, [JSON.stringify(params), address]);

  const onTransfer = async (item: any) => {
    // settick(item.tick)
    setitems(item)
    setOpen(true)
  }

  const onList = async (item: any) => {
    // settick(item.tick)
    setitems(item);
    setOpenA(true);
    setidx(null)
    let res = await onHttp({
      address: address,
    }, '/v2/Harmony/MarketTokens')
    console.log(res)
    if (res?.code == 0) {
      setList(res.data)
      setidx(0)
    }
  }

  const handleOk = async () => {
    if (!receiverAdd || !isAddress(receiverAdd)) {
      toast.error('Not an address');
      return
    }

    if (!amont || parseFloat(amont) > items?.amt || parseFloat(amont) <= 0) {
      toast.error('Please enter the correct amount')
      return
    }

    setLoadingb(true)
    try {
      let result = await (window as any).FactoryContract.transfer(items.tickAddress, receiverAdd, toBigString(amont, Math.pow(10, items.decimals)))
      let result1 = await result.wait();
      toast.success('Transfer Success')
      setOpen(false)
      setReceiverAdd(null)
      console.log(result1)
    } catch (error: any) {
      getErrorInfo(error)
      // toast.error(toast.error(JSON.stringify(error?.message)))
    }
    setLoadingb(false)
  };

  const onOptimizationChange = (val:any)=>{
    console.log(val)
    setidx(val)
  }

  const onSale = async ()=>{
    if(idx == null || !list){
      toast.error('please select token')
      return
    }
    if(!price || price <= 0){
      toast.error('Please enter price')
      return
    }

    if(!amount || amount <= 0){
      toast.error('Please enter amount')
      return
    }
    if(parseInt(amount) > items.amt){
      toast.error('amount not sufficient')
      return
    }

    setLoadingc(true)
    console.log(items.tickAddress)
    console.log(toBigString(parseFloat(amount), Math.pow(10, items.decimals)))
    console.log(toBigString(price, Math.pow(10, list[idx]?.deciamls)))
    console.log(list[idx]?.tokenAddress)
    try {
      let result = await (window as any).FactoryContract.sell(items.tickAddress,(toBigString(parseFloat(amount), Math.pow(10, items.decimals))),toBigString(price, Math.pow(10, list[idx]?.deciamls)),list[idx]?.tokenAddress)
      let result1 = await result.wait();
      toast.success('Transfer Success')
      setOpenA(false)
      setamount(null)
      setprice(null)
      setidx(null)
      console.log(result1)
    } catch (error: any) {
      getErrorInfo(error)
      // toast.error(toast.error(JSON.stringify(error?.message)))
    }
    setLoadingc(false)
  }

  const onRefresh = ()=>{
    setData(null)
    setLoading(true)
    setTotal(0)
    setTimeout(()=>{
      fetchData();
    },1000)
  }

  const onCopy = async(url:string)=>{
    let res = await copyPlaintext(url);
    if(res){
      toast.success('copy success!')
    }else{
      toast.error('copy failed!')
    }
  }

  const onERC = async(item: any)=>{
    setbalance('--')
    setOpenT(true)
    setamount('')
    setitems(item);
    let signer: any = await signerCall(walletProvider)
    let ERCabi:any = item.protocol == 'ERC20'?token20:token721
    const tokenContract = new Contract(item.tickAddress, ERCabi, signer);
    (window as any).tokenContract = tokenContract
    let myBalances = await tokenContract.balanceOf(address);
    if(Number(myBalances) == 0){
      setbalance(0)
    }
    if(myBalances){
        let num = todividedBy(myBalances,Math.pow(10,item.decimals))
        setbalance(num)
    }
  }

  const toERC = async()=>{
    if(balance == '--'){
      return
    }
    if(items?.protocol == 'ERC20'){
      if(!amount || amount > items.amt){
        toast.error('Please enter correct amount')
        return
      }
      setLoadingt(true)
      try {
        let result = await (window as any).tokenContract.toERC20(toBigString(parseFloat(amount), Math.pow(10, items.decimals)))
        await result.wait();
        toast.success('toERC20 Success')
      } catch (error) {
        getErrorInfo(error)
      }
      setLoadingt(false)
    }

    if(items?.protocol == 'ERC721'){
      if(!amount){
        toast.error('Please enter token id')
        return
      }
      setLoadingt(true)
      try {

        let ids = await (window as any).tokenContract.ownerOf(amount)
        if(ids != factoryAdd){
          setLoadingt(false)
          toast.error('Invalid Token ID')
          return
        }

        let result = await (window as any).tokenContract.toERC721(amount)
        await result.wait();
        toast.success('toERC721 Success')
      } catch (error) {
        getErrorInfo(error)
      }
      setLoadingt(false)
    }
  }

  const getprice = ()=>{
    if(parseFloat(price) && list && idx != null){
      let num1 = toBigString(1-list[idx]?.feeRate,parseFloat(price))
      return Math.floor(parseFloat(num1)  * 1000000) / 1000000
    }
    return '--'
  }

  const getUnitPrice = ()=>{
      if( parseFloat(price)  && parseFloat(amount)){
        let num = price/amount
        // console.log(list[idx]?.price)
        // console.log(parseFloat(num))
        let num1 = toBigString(num,list[idx]?.price)

        return Math.floor(parseFloat(num1)  * 1000000) / 1000000
        // console.log(num,num1)
        // return new BigNumber(num1).toFormat(6)
      }
      return '--'
  }



  return <div className="collect_box">

    <div className='refresh'>
      Result: {total} <img className={loading?'re_rotate':''} src={reImg} alt="" onClick={onRefresh} />
    </div>

    {/* 转账 */}
    <Modal
      title='Transfer'
      open={open}
      centered={true}
      onOk={handleOk}
      confirmLoading={loadingb}
      onCancel={() => { setOpen(false); }}
    >
      <div className='mint_time'>
        <h3>{items?.tick}</h3>
        <p>Receiver Address</p>
        <Input placeholder="input Receiver Address Likes (0x...)" value={receiverAdd} onChange={(ev) => setReceiverAdd(ev.target.value)} size="large" />
        <p>Amount ( Max {items?.amt} )</p>
        <Input placeholder="input Amount" value={amont} onChange={(ev) => setAmont(inputXS(ev.target.value))} size="large" />
      </div>
    </Modal>

    {/* {售卖} */}
    <Modal
      title={`List ${items?.tick} for Sale`}
      centered={true}
      open={openA}
      onOk={onSale}
      confirmLoading={loadingc}
      onCancel={() => setOpenA(false)} >
      <div className='sale_box'>
        <div>
          <div className='sa_card'>
            <h5>{items?.tick}</h5>
            <h3>{items?.amt}</h3>
            <p>{onAdd(items?.tickAddress)}</p>
          </div>
          <div className='sale_tip'>
            <p>Service Fee <b>{(idx != null) && (list[idx]?.feeRate * 100) || '--'}%</b></p>
            <p>Unit Price <b>{(idx != null) && `$${getUnitPrice()}/${items?.tick}`}</b> </p>
            <p>Total Revenue <b>
              {getprice()} {(idx != null) && list[idx]?.tokenName}
              <br />
              {(idx != null) && `$${(getprice() != '--')?Math.floor(parseFloat(toBigString(list[idx]?.price,getprice()))  * 1000000) / 1000000 :'--'}`}
            </b></p>
          </div>
        </div>


      </div>
      <div className='sale_from'>
        <Input placeholder="Amount" value={amount} maxLength={18} onChange={(ev) => setamount(inputZS(ev.target.value))} size="large" />
        <Select
          placeholder="Select a option"
          value={idx}
          onChange={onOptimizationChange}
          allowClear
        >
          {list && list.map((item: any,idx:number) => <Option value={idx} key={idx} > {item.logo && <img src={item.logo} />} {item.tokenName}</Option>)}

        </Select>
        
        <Input placeholder="Price" maxLength={18} value={price} suffix={(idx != null) && list[idx]?.tokenName} onChange={(ev) => setprice(inputXS(ev.target.value))} size="large" />
      </div>
    </Modal>

    {/* ERC20 提款 */}
    <Modal
      title={"Token To "+items?.protocol}
      centered={true}
      open={openT}
      onOk={toERC}
      confirmLoading={loadingt}
      onCancel={() => setOpenT(false)} >
      <div className='sale_box'>
        <div>
          <div className='sa_card'>
            <h5>{items?.tick}</h5>
            <h3>{items?.amt}</h3>
            {/* <p>{onAdd(items?.tickAddress)}</p> */}
          </div>
          <div className='sale_tip'>
            <p>Balance <b>{balance}</b></p>
            <p>Token <b>{onAdd(items?.tickAddress)}</b></p>
          </div>
        </div>

      </div>
      <div className='sale_from'>
        <Input placeholder={items?.protocol == 'ERC20'?"Amount":"Token id"} value={amount} onChange={(ev) => setamount(inputZS(ev.target.value))} size="large" />
      </div>
    </Modal>


    <ul className='collect_ul'>
      {
        data && data.map((item: any, idx: any) => <li key={idx}>
          <h4>{item?.tick} { item?.published && <p onClick={()=>onERC(item)}>{item?.protocol} <img src={teImg} alt="" /></p> }</h4>
          <h2>{item?.amt}</h2>
          <div>
            <p>{onAdd(item?.tickAddress)} <img src={copyImg} className="copy" onClick={()=>onCopy(item?.tickAddress)} alt="" /></p>
            <div className='flex_btn'>
              <Button type="primary" size='large' onClick={() => onList(item)} >List</Button>
              <Button type="primary" size='large' onClick={() => onTransfer(item)} >Transfer</Button>
            </div>
          </div>

        </li>)
      }
    </ul>
    {total > 0 && <Pagination defaultCurrent={1} pageSize={params.limit} total={total} onChange={onChange} />}
    {
      data && data.length == 0 && <div className='nodata'></div>
    }

  </div>



}

export default Collect;