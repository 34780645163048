import { Link, NavLink } from 'react-router-dom';
import logo from '../assets/img/logof.png';
import seerImg from '../assets/img/seer.svg';
import xImg from '../assets/img/ximg.svg';
import toast from 'react-hot-toast';
import { useEffect } from 'react';
import { factoryAdd, signerCall } from '../util/api';
import { Contract } from 'ethers'
import factoryAbi from '../util/factory.json'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'

const Footer = () => {

  const { address } = useWeb3ModalAccount()
  const { walletProvider }:any = useWeb3ModalProvider()

  useEffect(()=>{
    if(address){
      getFactoryContract()
    }
  },[address])

  const getFactoryContract = async()=>{
    try {
      let signer:any = await signerCall(walletProvider)
      const FactoryContract = new Contract(factoryAdd, factoryAbi,signer);
      (window as any).FactoryContract = FactoryContract
    } catch (error:any) {
      toast.error(toast.error(JSON.stringify(error)))
    }
    
  }

  return <div className='footer_box'>
    <div className='content'>
      <div className='logo_top'>
        <Link to="/" ><img src={logo} alt="logo" className='flogo' /></Link>
        <p>The New Era of InsFi for Inscription 2.0</p>
        <div>
          <a href="https://twitter.com/GatewayX_one" target={'_blank'} ><img src={xImg} alt="" /></a>
          <a href="https://to.seer.eco/#/#gatewayx1:genesis.seer.eco" target={'_blank'} ><img src={seerImg} alt="" /></a>
        </div>
      </div>
      <ul>
        <li>
          <h3>Feature</h3>
          <p><NavLink to="/launchpad">Launchpad</NavLink></p>
          <p onClick={()=>{toast('Coming Soon')}}>Bridge</p>
          <p onClick={()=>{toast('Coming Soon')}}>Swap</p>
          <p onClick={()=>{toast('Coming Soon')}}>Farm</p>
        </li>
        <li>
          <h3>Developers</h3>
          <p><a href="https://docs.gwx.one/" target={'_blank'}>Gitbook</a> </p>
          <p onClick={() => { toast('Coming Soon') }}>Github</p>
        </li>
        <li>
          <h3>Support</h3>
          <p><a href="https://to.seer.eco/#/#gatewayx1:genesis.seer.eco" target={'_blank'} >SEER</a></p>
          <p><a href="https://twitter.com/GatewayX_one" target={'_blank'} >Twitter</a></p>
        </li>
      </ul>
    </div>

      <div className='footer_div content'>
        <span>Copyright© 2024 | GatewayX</span>
        {/* <div className='head_icon'>
          <a href="https://app.seer.eco/" target={'_blank'} ><img src={xImg} alt="logo" /></a>
          <a href="https://app.seer.eco/" target={'_blank'} ><img src={bookImg} alt="logo" /></a>
          <a href="https://app.seer.eco/" target={'_blank'} ><img src={telImg} alt="logo" /></a>
        </div> */}
      </div>
  </div>
}

export default Footer;