import { useLocation, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Select, Space, Upload } from 'antd';

import '../../assets/css/create.less'
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { getErrorInfo, inputEN, inputXS, inputZS, isAddress, signerCall, toBigString } from '../../util/api';
import erc20 from '../../util/erc20.json'
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { Contract } from 'ethers'

const CreateView = () => {

  const { Option } = Select;

  const [tick, settick] = useState<string>();
  const [decimals, setdecimals] = useState<any>();
  const [totalSupply, settotalSupply] = useState<any>();
  const [max, setmax] = useState<any>();
  const [feeToken, setfeeToken] = useState<any>('');
  const [fee, setfee] = useState<any>();
  const [type, settype] = useState<string>();
  const [loading, setLoading] = useState<boolean>(false);

  const [objData, setobjData] = useState<any>();
  const [times, settimes] = useState<any>();

  const { address } = useWeb3ModalAccount()
  const { walletProvider }: any = useWeb3ModalProvider()

  let navigate = useNavigate();
  let localtion = useLocation();

  useEffect(() => {
    if (localtion.state) {
      console.log(localtion.state)
      setobjData(localtion.state)
    }
  }, [])

  const onFinish = async (values: any) => {
    if (address) {
      if (parseInt(totalSupply) <= 0 || parseInt(totalSupply) < parseInt(max)) {
        toast.error('totalSupply Greater than 0 and greater than Limit Per Mint')
        return
      }

      if(parseInt(decimals) > 18){
        toast.error('decimals max 18')
        return
      }

      if(!isAddress(feeToken)){
        toast.error('Gas Token Not an address')
        return
      }

      let tokendecimals: any = 18;
      // debugger
      if (feeToken != '0x0000000000000000000000000000000000000000') {

        let signer: any = await signerCall(walletProvider)
        const DecimalsContract = new Contract(feeToken, erc20, signer)
        const getDecimals = await DecimalsContract.decimals()
        if (getDecimals) {
          tokendecimals = Number(getDecimals)
        }
        console.log(getDecimals)
      }

      console.log(toBigString(fee , Math.pow(10, tokendecimals)), toBigString(totalSupply , Math.pow(10, decimals)), toBigString(max , Math.pow(10, decimals)))
      
      try {
        setLoading(true)
        let result = await (window as any).FactoryContract.setRule(objData.tickAddress,
          type == "ERC20" ? 0 : 1, feeToken,
          toBigString(fee,Math.pow(10, tokendecimals)), toBigString(totalSupply,Math.pow(10, decimals)), toBigString(max ,Math.pow(10, decimals)), decimals,
          times, tick)
        await result.wait();
        setLoading(false)
        toast.success('Setting Success')
        setTimeout(()=>{
          navigate(-1)
        },2000)
      } catch (error: any) {
        // toast.error(JSON.stringify(error?.message))
        getErrorInfo(error)
        setLoading(false)
      }
    }
  };

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo);
  };

  const onvalidator = (item: any) => {
    return (item && item.length > 0) ? Promise.resolve() : Promise.reject('Please input!');
  }


  return <div className='create_box inscription_box'>
    <div className='content'>
      <div className="tit">
        <h3>edit token</h3>
      </div>
      <p className='p'>Select the contract type you want to publish and upload the parameters</p>

      <div className='create_from'>
        <Form
          // name="basic"
          initialValues={{ remember: true }}
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >

          <div className='c_select'>
            <div className='c_tit'>
              <h3>Select Protocol Type</h3>
            </div>
            <Form.Item name="erc" rules={[{ required: true, message: 'Please Select Protocol Type!' }]}>
              <Select
                placeholder="Select a option"
                onChange={(val) => {
                  settype(val);
                  if (val == 'ERC721') {
                    setdecimals('0')
                    setmax('1')
                  }
                }}
                allowClear
              >
                <Option value="ERC721">ERC-721</Option>
                <Option value="ERC20">ERC-20</Option>
              </Select>
            </Form.Item>
          </div>

          <Form.Item
            name="tick"
            rules={[{ validator: () => onvalidator(tick) }]}
          >
            <div className='c_input'>
              <div>
                <h3>Tick</h3>
              </div>
              <Input placeholder='Enter the tick' value={tick} onChange={(ev) => { settick(inputEN(ev.target.value)) }} />
            </div>
          </Form.Item>

          <Form.Item
            name="decimals"
            rules={[{ validator: () => onvalidator(decimals) }]}
          >
            <div className='c_input'>
              <div>
                <h3>Decimals</h3>
              </div>
              <Input placeholder='Enter the Decimals' disabled={type == 'ERC721' ? true : false} value={decimals} onChange={(ev) => { setdecimals(inputZS(ev.target.value)) }} />
            </div>
          </Form.Item>

          <Form.Item
            name="totalSupply"
            rules={[{ validator: () => onvalidator(totalSupply) }]}
          >
            <div className='c_input'>
              <div>
                <h3>Total Supply</h3>
              </div>
              <Input placeholder='Enter the total Supply' value={totalSupply} onChange={(ev) => { settotalSupply(inputZS(ev.target.value)) }} />
            </div>
          </Form.Item>

          <Form.Item
            name="max"
            rules={[{ validator: () => onvalidator(max) }]}
          >
            <div className='c_input'>
              <div>
                <h3>Limit Per Mint</h3>
              </div>
              <Input placeholder='Enter the Limit Per Mint' value={max} onChange={(ev) => { setmax(inputZS(ev.target.value)) }} />
            </div>
          </Form.Item>

          <Form.Item
            name="feeToken"
            rules={[{ validator: () => onvalidator(feeToken) }]}
          >
            <div className='c_input'>
              <div>
                <h3>Mint Gas Token</h3>
              </div>
              <Input placeholder='Enter the Address Like 0X…' value={feeToken} onChange={(ev) => { setfeeToken(inputEN(ev.target.value)) }} />
            </div>
          </Form.Item>

          <Form.Item
            name="fee"
            rules={[{ validator: () => onvalidator(fee) }]}
          >
            <div className='c_input'>
              <div>
                <h3>Mint Gas </h3>
              </div>
              <Input placeholder='Enter the Limit Per Mint' value={fee} onChange={(ev) => { setfee(inputXS(ev.target.value)) }} />
            </div>
          </Form.Item>
          <Form.Item
            name="fee"
            rules={[{ validator: () => onvalidator(times) }]}
          >
            <div className='c_input'>
              <div>
                <h3>User Mint Times</h3>
              </div>
              <Input placeholder='Enter the Limit Per Mint' value={times} onChange={(ev) => { settimes(inputZS(ev.target.value)) }} />
            </div>
          </Form.Item>


          <Form.Item>
              <Button type="primary"
                loading={loading}
                size='large'
                htmlType="submit">
                Next
              </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  </div>
}

export default CreateView;