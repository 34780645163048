import bnbImg from '../assets/img/bnb.svg'
import harmonyImg from '../assets/img/harmony.svg'
// 测试

export const chaninArr = (process.env.NODE_ENV === "development")?[
    {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        rpcUrl: 'https://mainnet.infura.io/v3/d039103314584a379e33c21fbe89b6cb',
        explorerUrl: 'https://etherscan.io',
        logo:''
    },
    {
        chainId: 97,
        name: 'BNB Smart Chain Testnet',
        currency: 'tBNB',
        rpcUrl: 'https://endpoints.omniatech.io/v1/bsc/testnet/public',
        explorerUrl: 'https://testnet.bscscan.com',
        decimals: 18,
        hexId: '0x61',
        logo:bnbImg
    },
    {
        chainId: 1666700000,
        name: 'Harmony Testnet Shard 0',
        currency: 'ONE',
        rpcUrl: 'https://api.s0.b.hmny.io',
        explorerUrl: 'https://explorer.testnet.harmony.one',
        decimals: 18,
        hexId: '0x6357d2e0',
        logo:harmonyImg
    },
    {
        chainId: 42,
        name: '200',
        currency: 'bevm',
        rpcUrl: 'http://192.168.0.200:9944',
        explorerUrl: 'http://192.168.0.200:9944',
        decimals: 18,
        hexId: '0x2a',
        logo:''
    }
]:[
    {
        chainId: 1,
        name: 'Ethereum',
        currency: 'ETH',
        explorerUrl: 'https://etherscan.io',
        rpcUrl: 'https://mainnet.infura.io/v3/d039103314584a379e33c21fbe89b6cb',
        logo:''
    },
    {
        chainId: 56,
        name: 'Binance Smart Chain Mainnet',
        currency: 'BNB',
        rpcUrl: 'https://bsc-dataseed.binance.org',
        explorerUrl: 'https://bscscan.com',
        decimals: 18,
        hexId: '0x38',
        logo:bnbImg
    },
    {
        chainId: 1666600000,
        name: 'Harmony',
        currency: 'ONE',
        rpcUrl: 'https://rpc.ankr.com/harmony',
        explorerUrl: 'https://explorer.harmony.one',
        decimals: 18,
        hexId: '0x63564c40',
        logo:harmonyImg
    },
    
]

// 正式
// export const chaninArr = [
//     {
//         chainId: 1,
//         name: 'Ethereum',
//         currency: 'ETH',
//         explorerUrl: 'https://etherscan.io',
//         rpcUrl: 'https://cloudflare-eth.com',
//         logo:''
//     },
//     {
//         chainId: 56,
//         name: 'Binance Smart Chain Mainnet',
//         currency: 'BNB',
//         rpcUrl: 'https://bsc-dataseed.binance.org',
//         explorerUrl: 'https://bscscan.com',
//         decimals: 18,
//         hexId: '0x38',
//         logo:bnbImg
//     },
//     {
//         chainId: 1666600000,
//         name: 'Harmony',
//         currency: 'ONE',
//         rpcUrl: 'https://rpc.ankr.com/harmony',
//         explorerUrl: 'https://explorer.harmony.one',
//         decimals: 18,
//         hexId: '0x63564c40',
//         logo:harmonyImg
//     }
// ]