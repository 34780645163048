import { useEffect, useState } from 'react';
import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import logo from '../assets/img/logo.png';
import tranImg from '../assets/img/tran.svg';

import '../assets/css/bridge.less'
import { inputXS, onAdd, onHttp , usdtAbi, todividedBy, getErrorInfo, switchNetwork, toBigString } from '../util/api';
import avaImg from '../assets/img/ava.png'
import { Contract , BrowserProvider , ethers} from 'ethers'
import { Button, Input, Modal } from 'antd';
import toast from 'react-hot-toast';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { chaninArr } from '../util/config';
import { useNavigate } from 'react-router-dom';

const BridgeView = () => {

  const { address, chainId } = useWeb3ModalAccount()
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [type,setType] = useState<any>('')
  const navigate = useNavigate();

  const { walletProvider }:any = useWeb3ModalProvider()
  
  const [dataArr,setDataArr] = useState<[]>()

  const [values,setValues] = useState<any>('0')
  const [obja,setObja] = useState<any>({
    token:'',
    network:''
  })
  const [loadinga,setLoadinga] = useState<boolean>(false)
  const [balanceA,setbalanceA] = useState<any>('--')

  const [loading,setLoading] = useState<boolean>(false)
  const [loadingb,setLoadingb] = useState<boolean>(false)
  const [balanceB,setbalanceB] = useState<any>('--')
  const [objb,setObjb] = useState<any>({
    token:'',
    network:''
  })

  const [currentId,setCurrentId] = useState<any>()
  const [contractAdd,setcontractAdd] = useState<any>()
  const [toAdd,setToAdd] = useState<any>();
  const [decimals,setDecimals] = useState<any>(6);
  const [rate,setRate] = useState<any>();
  
  const [logoA,setlogoA] = useState<any>();
  const [logoB,setlogoB] = useState<any>();
  
  

  useEffect(()=>{
    if(address){
      getChains()
    }
  },[address])

  // 获取数据
  const getChains = async()=>{
    let res:any = await onHttp({},'/v2/Bridge/Chains')
    if(res && res.code == 0){
      setDataArr(res.data.list)
    }
  }

  useEffect(()=>{
    if(dataArr && obja.token && obja.network){
      setObjb({token:'',network:''})
      setLoadingb(false)
      setbalanceB('--')
      setToAdd('')
      setRate('')
      dataArr.forEach((item:any)=>{
        if(item.fromChain == obja.network &&  obja.token == item.fromToken){
          setDecimals(item.fromDecimals)
          setCurrentId(item.chainId)
          setcontractAdd(item.fromContract)
          getBalabceA(item.fromContract,item.fromDecimals,item.chainId)
        }
      })
    }else{
      // alert(1)
      setObjb({token:'',network:''})
      setLoadingb(false)
      setbalanceB('--')
      setbalanceA('--')
      setToAdd('')
      setRate('')
    }
  },[obja])

  useEffect(()=>{
    if(dataArr && objb.token && objb.network){
      
      dataArr.forEach((item:any)=>{
        if(item.fromChain == obja.network &&  obja.token == item.fromToken){
          let arr = item.targets
          arr.forEach((items:any)=>{
            if(items.targetChain == objb.network &&  objb.token == items.targetToken){
              // debugger
              setToAdd(items.recipient)
              setRate(items.rate)
              getBalabceB(items.targetContract,items.targetDecimals,items.chainId)
            }
          })
        }
      })
    }
  },[objb])

  // 获取余额
  const getBalabceA = async(contractAdd:any,decimals:any,chainId:any)=>{

    let arr = chaninArr.filter((item)=> item.chainId == chainId)
    setbalanceA('--')
    setLoadinga(true)
    console.log(arr)
    if(arr.length == 0){
      toast.error('get balace error')
      setLoadinga(false)
      setbalanceA('--')
      return 
    }
    const ethersProvider = new ethers.JsonRpcProvider(arr[0].rpcUrl)
    // ethersProvider.getBalance()
    // const ethersProvider = new BrowserProvider(walletProvider)
    // const signer = await ethersProvider.getSigner()
    const USDTContract = new Contract(contractAdd, usdtAbi,ethersProvider)
    try {
      let res = await USDTContract.balanceOf(address)
      if(Number(res) == 0){
        setbalanceA(0)
      }
      if(res){
          let num = todividedBy(res,Math.pow(10,decimals))
          setbalanceA(num)
      }
      setLoadinga(false)
    } catch (error) {
      getErrorInfo(error)
    }
    setLoadinga(false)
  }
  const getBalabceB = async(contractAdd:any,decimals:any,chainId:any)=>{
    setbalanceB('--')
    setLoadingb(true)

    let arr = chaninArr.filter((item)=> item.chainId == chainId)

    if(arr.length == 0){
      toast.error('get balace error')
      setLoadingb(false)
      setbalanceB('--')
      return 
    }

    console.log(arr)
    const ethersProvider = new ethers.JsonRpcProvider(arr[0].rpcUrl)
    const USDTContract = new Contract(contractAdd, usdtAbi,ethersProvider)
    try {
      let res = await USDTContract.balanceOf(address)
      if(Number(res) == 0){
        setbalanceB(0)
      }
      if(res){
          let num = todividedBy(res,Math.pow(10,decimals))
          setbalanceB(num)
      }
      setLoadingb(false)
    } catch (error) {
      getErrorInfo(error)
    }
    setLoadingb(false)
  }

  // 点击筛选
  const onSelect = (name:any)=>{
    if(!address){
      toast.error('Please connect wallet')
      return
    }
    if(name == '3' || name == '4'){
      if(!obja.token || !obja.network){
        toast.error('Please select above first')
        return
      }
    }
    setType(name)
    setIsModalOpen(true)
  }

  const getChainImg = (chian:string)=>{
    if(chian == 'bsc'){
      return <img src={chaninArr[1].logo} alt="" />
    }
    if(chian == 'harmony'){
      return <img src={chaninArr[2].logo} alt="" />
    }
    return null
    
  }

  const onTokens = async(item:any,idx?:any)=>{
    if(type == '1'){
        if(obja.token == item.fromToken && obja.network == item.fromChain){
          
        }else{
          setObja({
            token:item.fromToken,
            network:item.fromChain
          })
          setlogoA(item.logo)
          setObjb({token:'',network:''})
        }
    }
    if(type == '2'){
      if(obja.network != item){
        setObja({
          token:'',
          network:item
        })
        setObjb({token:'',
        network:''})
      }
    }
    if(type == '3'){
      if(objb.token == item.targetToken && objb.network == item.targetChain){
          return
      }
      setObjb({
        token:item.targetToken,
        network:item.targetChain
      })
      setlogoB(item.logo)
    }
    if(type == '4'){
      if(objb.network != item){
        setObjb({
          token:'',
          network:item
        })
      }
    }
    setIsModalOpen(false)
  } 

  const tokenArr = ()=>{
    if(dataArr && dataArr.length > 0){
      console.log(obja.network)
      console.log(obja.token)
      if(obja.network && !obja.token){
        let arr = dataArr.filter((item:any)=>{
          return item.fromChain == obja.network
        })
        console.log('---')
        console.log(arr)
        return arr
      }

      return dataArr
    }else{
      return []
    }
  }

  const networkArr = ()=>{
    if(dataArr && dataArr.length > 0){
      let arr:any = [];
      
      dataArr.map((item:any)=>{
        arr.push(item.fromChain)
      })
      
      const uniqueArr = Array.from(new Set(arr));
      console.log(uniqueArr)
      return uniqueArr || []
    }else{
      return []
    }
  }

  const tokenArrB = ()=>{
    if(dataArr && dataArr.length > 0){
      let arr:any = dataArr.filter((item:any)=>{
        return item.fromToken == obja.token
      })
      console.log('---')
      console.log(arr[0]['targets'])
      return arr[0]['targets']
    }else{
      return []
    }
  }

  const networkArrB = ()=>{
    if(tokenArrB() && tokenArrB().length > 0){
      let arr:any = [];
      tokenArrB().map((item:any)=>{
        arr.push(item.targetChain)
      })
      
      const uniqueArr = Array.from(new Set(arr));
      console.log(uniqueArr)
      return uniqueArr || []
    }else{
      return []
    }
  }

  const onConfirm = async()=>{
    if(!values || values == '0'){
      toast.error('Please enter the balance')
      return
    }
    if(parseFloat(values) > parseFloat(balanceA)){
      toast.error('Insufficient balance')
      return
    }
    if(!obja.token || !obja.network || !objb.token || !objb.network ){
      toast.error('Please select')
      return
    }
    if(currentId != chainId){
      let arr:any = chaninArr.filter((item)=> item.chainId == currentId)
      switchNetwork(walletProvider,arr[0])
      return
    }

    try {
      setLoading(true)
      const ethersProvider = new BrowserProvider(walletProvider)
      const signer = await ethersProvider.getSigner()
      const USDTContract = new Contract(contractAdd, usdtAbi,signer)

      console.log('----')
      console.log(contractAdd)
      console.log(toAdd)
      console.log(toBigString(values,Math.pow(10,6)))
      let result = await USDTContract.transfer(toAdd,toBigString(values,Math.pow(10,decimals)))
      await result.wait()
      toast.success('Transfer success')
      setTimeout(()=>{
        setLoading(false)
        navigate(0)
      },2000)
    } catch (error:any) {
      console.log(error)
      getErrorInfo(error)
      setLoading(false)
    }
  }

  const onMax = ()=>{
    if(parseFloat(balanceA) > 0){
      setValues(balanceA)
    }
  }

  const onTran = ()=>{
    if(!obja.token || !obja.network || !objb.token || !objb.network ){
      toast.error('Please select')
      return
    }
    // let tobja = obja
    let tobjb = objb
    setObja(tobjb)
  }

  const getName = (name:string)=>{
    if(name == 'bsc'){
      return 'BNB Chain'
    }
    if(name == 'harmony'){
      return 'Harmony'
    }
    return name
  }


  return <div className='bridge_box'>
    <div>
    <i>{address?onAdd(address):'Please connect wallet'}</i>
      <div className='br_border'>
        <div className='br_select'>
          <div>
            <img src={(logoA && obja?.token)?logoA:avaImg} alt="" />
            <div>
              <p>Token</p>
              <span onClick={()=>onSelect('1')}>{obja?.token || <u>Select</u>}</span>
            </div>
          </div>
          <div>
            {getChainImg(obja?.network)}
            <div>
              <p>Network</p>
              <span onClick={()=>onSelect('2')}>{getName(obja?.network) || <u>Select</u>}</span>
            </div>
          </div>
        </div>
        <div className='br_input'>
          {/* <span>Max</span> */} 
          {/* <input type="text" value={value} onChange={(ev:any)=>{setValue(inputXS(ev.target.value))}} /> */}
          <Input placeholder="" value={values} maxLength={18} onChange={(ev) => setValues(inputXS(ev.target.value))} size="large" />
          <div>
            <p>Balance</p>
            <p><Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} />} spinning={loadinga} />{!loadinga && balanceA}</p>
          </div>
          <span className='max' onClick={onMax}>Max</span>
        </div>  
      </div>

      <div className='tranimg'>
        <img onClick={onTran} src={tranImg} />
      </div>

      {/* <i>{address?onAdd(address):'Please connect wallet'}</i> */}
      <div className='br_border'>
        <div className='br_select'>
          <div>
            <img src={(logoB && objb?.token)?logoB:avaImg} alt="" />
            <div>
              <p>Token</p>
              <span onClick={()=>onSelect('3')}>{objb?.token || <u>Select</u>}</span>
            </div>
          </div>
          <div>
            {getChainImg(objb?.network)}
            <div>
              <p>Network</p>
              <span onClick={()=>onSelect('4')}>{getName(objb?.network) || <u>Select</u>}</span>
            </div>
          </div>
        </div>
        <div className='br_input'>
          {/* <span>Max</span> */}
          <h4>{(rate && values)?(toBigString(parseFloat(values),rate)):'-'}</h4>
          <div>
            <p>Balance</p>
            <p><Spin indicator={<LoadingOutlined style={{ fontSize: 18 }} />} spinning={loadingb} />{!loadingb && balanceB}</p>
          </div>
          {/* <span>Get</span> */}
        </div>  
      </div>

      <Button type='primary' size='large' disabled={!address} loading={loading} onClick={onConfirm} >{currentId == chainId?'Confirm':'Switching chain'}</Button>
      <p className='p'>Estimated transaction time: 2 minutes</p>

    </div>
    <p className='p'>Powered By <img src={logo} alt="" /></p>

    <Modal title={(type == '1' || type == '3')?'Token':'Network'} open={isModalOpen} width={380} footer={false}  onCancel={()=>setIsModalOpen(false)}>
          <div className='bridge_ul'>
            {
              type == '1' && tokenArr().map((item:any,idx)=> <li key={idx} onClick={()=> onTokens(item) } >
                <div>
                  <img src={item.logo || avaImg} alt="" />
                  <div>
                    <span>{getName(item.fromChain)}</span>
                    <p>{item.fromToken}</p>
                  </div>
                </div>
              </li>)
            }

            {
              type == '2' && networkArr().map((item:any,idx)=> <li key={idx} onClick={()=> onTokens(item) } >
                <div>
                  {getChainImg(item) || <img src={avaImg} alt="" />}
                  <div>
                    <p>{getName(item)}</p>
                  </div>
                </div>
              </li>)
            }

            {
              type == '3' && tokenArrB().map((item:any,idx:any)=> <li key={idx} onClick={()=> onTokens(item) } >
                <div>
                  <img src={item.logo || avaImg} alt="" />
                  <div>
                    <span>{getName(item.targetChain)}</span>
                    <p>{item.targetToken}</p>
                  </div>
                </div>
              </li>)
            }

            {
              type == '4' && networkArrB().map((item:any,idx:any)=> <li key={idx} onClick={()=> onTokens(item) } >
                <div>
                  {getChainImg(item) || <img src={avaImg} alt="" />}
                  <div>
                    <p>{getName(item)}</p>
                  </div>
                </div>
              </li>)
            }
            
            
          </div>
    </Modal>
  </div>
}

export default BridgeView;

